/*
 Copyright 2019, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at
 
 http://www.apache.org/licenses/LICENSE-2.0
 
 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */


import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import Cookies from 'js-cookie';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MenuIcon from '@material-ui/icons/Menu';
import Button from '@material-ui/core/Button';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import Notifications from './Notifications'
import logo from '../media/logo-knowhereai.png';
import { checkLogin, logout } from '../actions/user'
import { logoutOrg } from '../actions/organization';
import ProfileNav from './ProfileNav';
import userManager from '../utils/userManager';
import { stripOIDC } from '../utils/Helpers';


import { setConfirmationModalOpen, setDrawerOpen, setManufacturerPortal } from '../actions/interactions';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  img: {
    padding: theme.spacing(1,0,.4)
  },
  infoItems: {
    display: 'flex',
    alignItems: 'center',
  },
  btnText: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'inherit'
    },
  },
  menuBtn: {
    marginLeft: theme.spacing(-1.5),
    // [theme.breakpoints.up('md')]: {
    //   display: 'none'
    // },
  },
  userInfo: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'inherit'
    },
  }
}));

const mapStateToProps = state => {
  return state.oidc ? {
    isAuthed: state.portal.isAuthed,
    currentUser: state.portal.currentUser,
    org: state.portal.selectedOrg,
    manufacturerPortal: state.portal.manufacturerPortal,
    oidcUser: state.oidc.user,
    oidcLoadingUser: state.oidc.isLoadingUser,
  } :
  {
    isAuthed: state.portal.isAuthed,
    currentUser: state.portal.currentUser,
    org: state.portal.selectedOrg,
    manufacturerPortal: state.portal.manufacturerPortal,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    logoutUser: () => dispatch(logout()),
    openConfirmationModal: (item) => dispatch(setConfirmationModalOpen(item)),
    logoutOrg: () => dispatch(logoutOrg()),
    resetAuth: () => dispatch(checkLogin()),
    openDrawer: () => dispatch(setDrawerOpen(true)),
    closeManufacturerPortal: () => dispatch(setManufacturerPortal(false)),
  };
}

const ConnectedCachengobar = props => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [isNotifOpen, openNotifications] = useState(false)
  const history = useHistory()
  

  useEffect(() => {
    const loggedIn = props.oidcUser || Cookies.get('Authorization');
    if (!props.oidcLoadingUser && loggedIn) {
      props.resetAuth();
    }
  }, [props.oidcUser, props.oidcLoadingUser]);

  const logoutManufacturerPortal = () => {
    props.closeManufacturerPortal()
    history.push('/')
    props.openConfirmationModal({ show: false, message: null, onYes: function () { } })
  }

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleChangeOrg = () => {
    handleClose()
    window.sessionStorage.removeItem("Org");
    props.logoutOrg()
  };
  const toggleLogOutModal = () => {
    setAnchorEl(null);
    props.openConfirmationModal(
      {
        show: true,
        settings: { title: 'Log Out', confirmText:'Yes, Log Out'},
        message: "Are you sure you want to log out of Knowhere?",
        onYes: () => {
          props.logoutUser()
          .then(props.openConfirmationModal({ show: false, message: null, onYes: function () { } }));
          if (props.oidcUser) {
            userManager.signoutRedirect();
          }
          window.sessionStorage.removeItem("Org");
        }
      }
    )
  };
  
  const toggleLogOutMPModal = () => {
    setAnchorEl(null);
    props.openConfirmationModal(
      {
        show: true,
        settings: { title: 'Exit Manufacturer Portal', confirmText: 'Yes, Exit' },
        message: "Are you sure you want to exit the Cachengo Manufacturer Portal?",
        onYes: () => { logoutManufacturerPortal ()}
      }
    )
  };

  const toggleNotification = ()=>{
    openNotifications(!isNotifOpen)
  }
  const closeNotification = () => {
    openNotifications(false)
  }
  return (
    <div className={classes.root}>
      <AppBar position="fixed" color="default" className={classes.appBar}>
        <Toolbar>
          {props.isAuthed &&
            <div >
              {props.manufacturerPortal ?
              <div className={classes.infoItems}>
                <Button
                  color='primary'
                  size='large'
                  onClick={toggleLogOutMPModal}
                  startIcon={<ExitToAppIcon size='large' />}
                >
                  <span className={classes.btnText}>Exit manufacturer portal</span>
                </Button>
                </div>
              :
              <div className={classes.infoItems} >
                <IconButton
                  className={classes.menuBtn}
                  onClick={props.openDrawer}
                  aria-label={'menu'}
                >
                  <MenuIcon />
                </IconButton>

                <div>
                <IconButton
                  style={{ color: '#aaa' }}
                  onClick={toggleNotification}
                  aria-label={'notifications'}
                >
                  <NotificationsIcon />
                </IconButton>
                {isNotifOpen &&
                  <Notifications
                    open={isNotifOpen}
                    closeNotification={closeNotification}
                  />
                }
                </div>
              <div className={classes.userInfo} >
                <ListItem
                  button
                  onClick={handleMenu}
                >
                  <ListItemText
                    primary={stripOIDC(props.currentUser.username)}
                    secondary={props.org.name} />
                </ListItem>
                <ProfileNav
                  anchorEl={anchorEl}
                  open={open}
                  handleClose={handleClose}
                  openLogout={toggleLogOutModal}
                  handleChangeOrg={handleChangeOrg}
                />
              </div>
              </div>
              }  
            </div>
          }       
          <div className={classes.grow} />
          <div className={classes.img}>
            <img src={logo} alt="Cachengo" height="64" />
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}

const Cachengobar = connect(mapStateToProps, mapDispatchToProps)(ConnectedCachengobar);

export default Cachengobar;

import * as React from 'react';
import { useState, useRef, useEffect } from 'react';

import PropTypes from 'prop-types';
import { alpha } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
 
import { connect } from "react-redux";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import { fetchClusters, getDevicesByProduct, removeCluster, getClusterInfo } from '../utils/Cachengo';
import ErrorIcon from '@material-ui/icons/Error';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import TemporaryDrawer from './TemporaryDrawer';
import Grid from '@material-ui/core/Grid';
import CardHeader from '@material-ui/core/CardHeader';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AlbumIcon from '@material-ui/icons/Album';
import ClusterGeneralInfo from './ClusterGeneralInfo';
import { setDevicesToInstall, removeDevicesFromInstallation } from '../actions/installation';

import { removeEventHandler, addEventHandler } from '../actions/items';

import {
  setConfirmationModalOpen,
  setSnackBarMessage,
} from "../actions/interactions";
import {
  removeMultipleDevices,
} from "../actions/installation";




const mapStateToProps = (state) => {
    return {
      payInfo: state.portal.payInfo || {},
    };
};

function mapDispatchToProps(dispatch) {
  return {
    removeEventHandler: (key) => dispatch(removeEventHandler(key)),
    addEventHandler: (key, handler) => dispatch(addEventHandler(key, handler)),
    setDevicesToInstall: (device) => dispatch(setDevicesToInstall(device)),
    removeDevicesFromInstallation: () => dispatch(removeDevicesFromInstallation()),
    openConfirmationModal: (item) => dispatch(setConfirmationModalOpen(item)),
    removeMultipleDevices: (handle) => dispatch(removeMultipleDevices(handle)),
    setSnackBarMessage: (message, type) => dispatch(setSnackBarMessage(message, type)),
    // setItemToInstall: (item) => dispatch(setItemToInstall(item)),
  }
}

const useStyles = makeStyles((theme) => ({
  bread:{
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    paper: {
      padding: theme.spacing(2),
      // textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    resources: {
      padding: theme.spacing(1),
      // textAlign: 'center',
      color: theme.palette.text.secondary,
      height: '100vh',
    },
    header: {
      textAlign: 'center',
      justifyContent: 'center',
      // padding: theme.spacing(2),
      width: '100%',
      textAlign: 'left',
      background: '#eaeded',
      color: 'black',
      fontWeight: 'bold',
      border: '1.5px solid black'
    },
    card: {
      border: '1px solid #d5dbdb',
      // maxHeight: '4vh'
      // borderRadius: '1px',
      // borderWeight: 'light',
    },
  }
}));

function createData(id, name, cluster_id, servers, agents) {
  return {
    id,
    name,
    cluster_id,
    servers,
    agents,
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
  },
  {
    id: 'cluster_id',
    numeric: true,
    disablePadding: false,
    label: 'Cluster ID',
  },
  {
    id: 'servers',
    numeric: true,
    disablePadding: false,
    label: 'Servers',
  },
  {
    id: 'agents',
    numeric: true,
    disablePadding: false,
    label: 'Agents',
  },
];

function EnhancedTableHead(props) {

  const tableRef = useRef();

  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };


  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {/* <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all clusters',
            }}
          /> */}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const numSelected = props.numSelected;
//   const theme = createTheme({
//     palette: {
//         primary: {
//         light: '#fc5001',
//         main: '#fc5001',
//         dark: '#fc5001',
//         contrastText: '#fff',
//         },
//     },
//   });
//   let theme = createTheme()
  const goToNewCluster = () => {
    props.history.push('/new_cluster')
  }
  const handleDeleteCluster = (rowData) => {
    let handles = rowData.map((device) => device.handle);
    console.log(rowData)
    console.log(handles)
    return removeCluster(handles).then(() => {
      props.handleRefreshData()
      props.setSelectedClusters([]);
      props.setSelected([]);
    });
  };

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
      style={{justifyContent:'space-between'}}
    >
      {numSelected === 1 ? (
        <div>
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
          <Tooltip title="Terminate Cluster">
          <IconButton onClick={() => {
            props.openConfirmationModal({
              show: true,
              message:
                "Are you sure you want to permanently delete the selected cluster?",
              onYes: () => {
                handleDeleteCluster(props.selectedClusters).then(
                  props.openConfirmationModal({
                    show: false,
                    message: null,
                    onYes: function () {},
                  })
                );
              },
            })
          }}>
            <DeleteIcon />
        </IconButton>
        </Tooltip>
        </Typography>
        </div>
      ) : (
        <div>
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Clusters
          <Tooltip title="Filter list">
        <IconButton>
            <FilterListIcon />
        </IconButton>
        </Tooltip>
        </Typography>

        </div>
      )}

      {/* {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )} */}
        <Button
            style={{ height: "fit-content", backgroundColor:'#fc5001', fontWeight:'bold', color:'white'
        }}
            // color="primary"
            // color='white'
            variant="contained"
            // textColor="white"/
            // theme={theme}
            onClick={goToNewCluster}
        >
            Create Cluster
        </Button>
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function ConnectedEnhancedClusterTable(props) {
  const tableRef = useRef();

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [rows, setRows] = useState([]);
  const [devices, setDevices] = useState([]);  
  const [clusters, setClusters] = useState([]);
  const [loadState, setLoadState] = useState(false);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [selectedClusters, setSelectedClusters] = useState([]);
  const [clusterInfo, setClusterInfo] = useState({});
  const [clusterDevs, setClusterDevs] = useState([]);
  const [infoLoadState, setInfoLoadState] = useState(true);


  const classes = useStyles();

  useEffect(() => {
    props.removeDevicesFromInstallation();
    handleRefreshData();
  }, [])


  const clearSelectedItems = (handles) => {
    props.removeDevicesFromInstallation();
    setSelectedDevices([]);
    setSelected([]);
  };

  const refreshData = () => {
    tableRef.current && tableRef.current.refreshTable();
  };

  const handleRefreshData = ()=> {
    clearSelectedItems();
    fetchClusters().then(res => {
      if (res && res.success && res.clusters.length > 0) { 
        setClusters(res.clusters)
        const cluster_handles = res.clusters.map((cluster) => cluster.handle)
        let newClusters = res.clusters
        // const devices = res.devices.filter(device => device.is_vm === true);
        // const devices = testData.filter(device => device.is_vm === true);
        // setDevices(devices)
        // console.log(devices)
        let newRows = []

        res.clusters.forEach(function(cluster,i) {
          newRows.push(createData(i,cluster.name,cluster.handle,cluster.servers,cluster.agents))
        })
        console.log(newRows)
        setRowsPerPage(newRows.length)
        setRows(newRows)
        setLoadState(true)

      } else {
        setRowsPerPage(0)
        setRows([])
        setDevices({})
        setLoadState(true)
      }
    });
    refreshData();
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    setInfoLoadState(false)

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    var newSelectedClusters = []
    newSelected.forEach((id) => {
      newSelectedClusters.push(
        clusters.filter(
          cluster => cluster.handle === visibleRows.filter(
            dev => dev.id === id
          )[0].cluster_id
        )[0]
      )
    })

    const clusterHandles = newSelectedClusters.map((dev)=>dev.handle)
    let leaderDev = [[]]
    if (newSelectedClusters.length > 0) {
      leaderDev = newSelectedClusters[0].leader
    }

    console.log(leaderDev.length)
    console.log(leaderDev)
    console.log(newSelectedClusters)

    let clusInfo = []
    let newClusterInfo = {}

    console.log(clusterHandles)
    console.log(leaderDev)
    if (clusterHandles.length === 0 || clusterHandles.length > 1 || leaderDev[0].length === 0){
      props.removeDevicesFromInstallation()
      props.setDevicesToInstall([])
      setClusterInfo(null)
      setSelectedDevices([])
      setSelectedClusters(newSelectedClusters)
      setInfoLoadState(true)
    } else {
      console.log('about to run')
      getClusterInfo(clusterHandles).then(res => {
        // setClusterInfo(res.info)
        if (res && res.success){
          clusInfo = res.info
        }
      }).then(() => {
        newSelectedClusters.forEach((cluster)=>{
          if (clusInfo.length > 0){
            newClusterInfo[cluster.handle] = clusInfo[0]
          } else {
            newClusterInfo[cluster.handle] = {} 
          }
        })
        setClusterInfo(newClusterInfo)
        console.log(newClusterInfo)
        // setClusterDevs(newClusterInfo[0].connections)
        setInfoLoadState(true)
      })
      props.removeDevicesFromInstallation()
      props.setDevicesToInstall(leaderDev)
      setSelectedDevices(leaderDev)
      setSelectedClusters(newSelectedClusters)
    }

    // getDeviceInfo(devHandles).then(res => {
    //   setDeviceInfo(res.info)
    //   devInfo = res.info
    // }).then( () =>
    //   {
    //     getAllInstallations(devHandles).then(res => {
    //       setDeviceInstallations(res.installations)
    //       devInstallations = res.installations
    //     }).then(() => {
    //       newSelectedDevs.forEach((device)=> {
    //         newDeviceInstallations[device.handle] = devInstallations.filter((app) => app.device === device.handle && app.state.toLowerCase() === "installed")
    //         newDeviceConnections[device.handle] = devInfo.filter((dev) => dev.handle === device.handle).map((info) => info.connections)
    //         newDeviceKeys[device.handle] = devInfo.filter((dev) => dev.handle === device.handle).map((info) => info.keys)
    //       })
    //       newDeviceInstallations = parseInstallations(newSelectedDevs.map((dev)=>dev.handle),newDeviceInstallations)
    //       newDeviceConnections = parseConnections(newSelectedDevs.map((dev)=>dev.handle),newDeviceConnections)
    //       newDeviceKeys = parseKeys(newSelectedDevs.map((dev)=>dev.handle),newDeviceKeys)
      
    //       setDeviceInstallations(newDeviceInstallations)
    //       setDeviceConnections(newDeviceConnections)
    //       setDeviceKeys(newDeviceKeys)
    //       setInfoLoadState(true)
      
    //     })
    //   }
    // )

    setSelected(newSelected);
  };

  const history = props.history;

  // const goToNewInstance = () => {
  //   history.push('/new_instance')
  // }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  console.log('running')
  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, rows],
  );
  console.log(rows)
  console.log(visibleRows)
  console.log('test2')
  return (
    // <Box sx={{ width: '100%' }}>
    <div style={{height:'100vh'}}>

      <Grid container spacing={3}>
      <Grid item xs={12}>
      <div className={classes.bread}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          <Link color="inherit" component="button" variant="body2" onClick={() => {props.history.push('/')}}>
            Home
          </Link>
          <Link color="inherit"  component="button" variant="body2" onClick={() => {props.history.push('/rentnode')}}>
            Rent-a-Node
          </Link>
          <Typography color="textPrimary">Clusters</Typography>
        </Breadcrumbs>
      </div>
    <Paper sx={{ width: '100%', overflow: 'hidden', maxHeight: '35vh' }}>
        <EnhancedTableToolbar numSelected={selected.length} history={history} 
            openConfirmationModal={props.openConfirmationModal} selectedClusters={selectedClusters}
            handleRefreshData={handleRefreshData}
            setSelectedClusters={setSelectedClusters}
            setSelected={setSelected}
        />
        <TableContainer style={{ maxHeight: '35vh' }}>
          <Table
            stickyHeader
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      {row.name}
                    </TableCell>
                    <TableCell align="right">{row.cluster_id}</TableCell>
                    {/* {
                        (row.instance_state === true)
                            ? <TableCell align="right" style={{color:'#1d8102'}}><CheckCircleOutlineIcon fontSize="small" style={{verticalAlign: 'bottom', color:'#1d8102'}}/>{"Running"}</TableCell>
                            : <TableCell align="right" style={{color:'red'}}><HighlightOffIcon fontSize="small" style={{verticalAlign: 'bottom', color:'red'}}/>{"Stopped"}</TableCell>
                    } */}
                    <TableCell align="right">{row.servers}</TableCell>
                    <TableCell align="right">{row.agents}</TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={-1}
          component="div"
          count={rows.length}
          rowsPerPage={rows.length}
          page={page}
          // onChangePage={handleChangePage}
          // onChangeRowsPerPage={handleChangeRowsPerPage}
          on
        />
      </Paper>
      </Grid>
      {/* <Divider style={{backgroundColor:'black'}}></Divider> */}
      <Grid item xs={12} className={classes.resources}>
          <Paper className={classes.resources} style={{height:'100vh'}}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
              { selected.length === 1 ?
                <CardHeader style={{borderBottom: '1px solid #d5dbdb'}} titleTypographyProps={{variant:'h6', fontWeight:'bold' }} xs={12} title={visibleRows.filter(device => device.id === selected[0])[0].name} className={classes.header}></CardHeader> :
                selected.length >= 1 ?
                <CardHeader titleTypographyProps={{variant:'h6', fontWeight:'bold' }} xs={12} title="Metrics" className={classes.header}></CardHeader> :
                <CardHeader titleTypographyProps={{variant:'h6', fontWeight:'bold' }} xs={12} title="Select a cluster" className={classes.header}></CardHeader>
              }
              </Grid>
              { selected.length === 1 && infoLoadState && clusterInfo !== null?
              <>
              <Grid item xs={12}>
                  <Accordion className={classes.heading} defaultExpanded>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading} fontWeight="fontWeightBold">
                        Cluster Devices
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{background:'white'}}>
                      <ClusterGeneralInfo
                        devices={clusterInfo[Object.keys(clusterInfo)[0]].connections}
                      />
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </> : <></>}
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
        </Grid>
      </Grid>
      {/* <TemporaryDrawer></TemporaryDrawer> */}
    </div>

    // </Box>
  );
}

const EnhancedClusterTable = connect(
    mapStateToProps, mapDispatchToProps
  )(ConnectedEnhancedClusterTable);
  
  export default EnhancedClusterTable;

/*
 Copyright 2019, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at
 
 http://www.apache.org/licenses/LICENSE-2.0
 
 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */

import React, {Component, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';

import { connect } from 'react-redux';
import { setDrawerOpen, setConfirmationModalOpen } from '../actions/interactions';
import { logoutOrg, setOrganization } from '../actions/organization';
import { logout } from '../actions/user'
import SnackBar from './SnackBar';
import AppInstallModal from './modals/AppInstallModal';
import ConfirmationModal from './modals/ConfirmationModal';
import HomeUnauth from './HomeUnauth'
import UserConfirm from './UserConfirm'
import SelectOrgModal from './modals/SelectOrgModal';
import Routes from './Routes';
import ManufacturerRoutes from './ManufacturerRoutes';

import DrawerBody from './DrawerBody';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

import userManager from '../utils/userManager';



const mapStateToProps = state => {
  return state.oidc ?
    {
    open: state.portal.isDrawerOpen,
    org: state.portal.selectedOrg,
    isAuthed: state.portal.isAuthed,
    currentUser: state.portal.currentUser,
    oidcUser: state.oidc.user,
  } :
  {
    open: state.portal.isDrawerOpen,
    org: state.portal.selectedOrg,
    isAuthed: state.portal.isAuthed,
    currentUser: state.portal.currentUser,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    closeDrawer: () => dispatch(setDrawerOpen(false)),
    logoutUser: () => dispatch(logout()),
    openConfirmationModal: (item) => dispatch(setConfirmationModalOpen(item)),
    logoutOrg: () => dispatch(logoutOrg()),
    setOrg: (payload) => dispatch(setOrganization(payload)),
  };
}

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
      flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
  }
});

class ConnectedDrawerComponent extends Component {
  constructor(props) {
    super(props);

    this.componentCleanup = this.componentCleanup.bind(this)
  }

  componentCleanup() { 
    // whatever you want to do when the component is unmounted or page refreshes
    if (this.props.org.handle != "") {
      window.sessionStorage.setItem("Org", JSON.stringify(this.props.org));
    }
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.componentCleanup);
  }

  componentWillUnmount() {
    // remove the event handler for normal unmounting
    this.componentCleanup();
    window.removeEventListener('beforeunload', this.componentCleanup);
  }

  logoutOrg() {
    this.props.logoutOrg()
      .then(res => window.sessionStorage.removeItem("Org"));
  }

  logoutUser() {
    this.props.logoutUser()
      .then(res => {
        if (this.props.oidcUser) {
          userManager.signoutRedirect();
        }
        window.sessionStorage.removeItem("Org");
      });
  }

  render() {
    const { classes } = this.props;

    var newOrg = JSON.parse(window.sessionStorage.getItem("Org")) || "";

    const validOidcUser = this.props.oidcUser && !this.props.oidcUser.expired;

    if (!this.props.isAuthed && !validOidcUser) {
      return (
        <div className="container">
          <HomeUnauth />
          <SnackBar />
        </div>
      );
    }

    if (!this.props.currentUser.confirmed && !validOidcUser){
      return (
        <div>
          <UserConfirm currentUser={this.props.currentUser}/>
          <ConfirmationModal />
          <SnackBar />
        </div>
      );
    }

    if (!this.props.org || this.props.org.handle === '' ){
      if (newOrg !== "")  {
        this.props.setOrg(newOrg)
        return (
          <div></div>
        )
      }
      return (
        <div>
          <CssBaseline /> 
          <SelectOrgModal />
          {(this.props.currentUser.is_superuser || this.props.currentUser.is_manufacturer)
            && <ManufacturerRoutes />
          }
          <ConfirmationModal />
          <SnackBar />
        </div>
      );
    }

    return (
      <div className={classes.root}>  
        <CssBaseline /> 
        {/* Small Screens */}
        <Hidden xsUp implementation="css">
          <Drawer
            className={classes.drawer}
            variant="temporary"
            open={this.props.open}
            onClose={this.props.closeDrawer}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <DrawerBody 
              small={true}
              closeDrawer={this.props.closeDrawer}
              org={this.props.org}
              currentUser={this.props.currentUser}
              logoutUser={this.logoutUser.bind(this)}
              logoutOrg={this.logoutOrg.bind(this)}
              openConfirmationModal={this.props.openConfirmationModal}
            />
          </Drawer>
        </Hidden>
        {/* Big Screens */}
        <Hidden xsUp implementation="css">
          <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <Toolbar style={{ height: '90px' }} />
            <div className={classes.drawerContainer} >
              <DrawerBody />
            </div>
          </Drawer>
        </Hidden>

        <Routes />
        <AppInstallModal />
        <ConfirmationModal />
        <SnackBar />
      </div>
    );
  }
}

const DrawerComponent = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ConnectedDrawerComponent));

export default DrawerComponent;

import * as React from 'react';
import { useState, useRef, useEffect } from 'react';

import PropTypes from 'prop-types';
import { alpha } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
 
import { connect } from "react-redux";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import { getDevicesByProduct } from '../utils/Cachengo';
import ErrorIcon from '@material-ui/icons/Error';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import TemporaryDrawer from './TemporaryDrawer';
import Grid from '@material-ui/core/Grid';
import CardHeader from '@material-ui/core/CardHeader';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AlbumIcon from '@material-ui/icons/Album';
import DeviceGeneralInfo from './DeviceGeneralInfo';
import { setDevicesToInstall, removeDevicesFromInstallation } from '../actions/installation';
import InstalledApps from './InstalledApps';
import InstalledSwitches from './InstalledSwitches';
import CLIModal from './CLIModal';
import { getDeviceInfo, getAllInstallations } from '../utils/Cachengo';

import {
  setConfirmationModalOpen,
  setSnackBarMessage,
} from "../actions/interactions";
import {
  removeMultipleDevices,
} from "../actions/installation";

import { removeEventHandler, addEventHandler } from '../actions/items';

import { removeDevice, getProducts, updateDevice } from "../utils/Cachengo";

const mapStateToProps = (state) => {
    return {
      payInfo: state.portal.payInfo || {},
      item: state.portal.itemToInstall,
      devices: state.portal.devicesToInstall,
    };
};

function mapDispatchToProps(dispatch) {
  return {
    removeEventHandler: (key) => dispatch(removeEventHandler(key)),
    addEventHandler: (key, handler) => dispatch(addEventHandler(key, handler)),
    setDevicesToInstall: (device) => dispatch(setDevicesToInstall(device)),
    removeDevicesFromInstallation: () => dispatch(removeDevicesFromInstallation()),
    openConfirmationModal: (item) => dispatch(setConfirmationModalOpen(item)),
    removeMultipleDevices: (handle) => dispatch(removeMultipleDevices(handle)),
    setSnackBarMessage: (message, type) => dispatch(setSnackBarMessage(message, type)),
    // setItemToInstall: (item) => dispatch(setItemToInstall(item)),
  }
}

const useStyles = makeStyles((theme) => ({
  bread:{
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    paper: {
      padding: theme.spacing(2),
      // textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    resources: {
      padding: theme.spacing(1),
      // textAlign: 'center',
      color: theme.palette.text.secondary,
      height: '100vh',
    },
    header: {
      textAlign: 'center',
      justifyContent: 'center',
      // padding: theme.spacing(2),
      width: '100%',
      textAlign: 'left',
      background: '#eaeded',
      color: 'black',
      fontWeight: 'bold',
      border: '1.5px solid black'
    },
    card: {
      border: '1px solid #d5dbdb',
      // maxHeight: '4vh'
      // borderRadius: '1px',
      // borderWeight: 'light',
    },
  }
}));

function createData(id, name, instance_id, instance_state, instance_type, private_v4) {
  return {
    id,
    name,
    instance_id,
    instance_state,
    instance_type,
    private_v4,
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
  },
  {
    id: 'instance_id',
    numeric: true,
    disablePadding: false,
    label: 'Instance ID',
  },
  {
    id: 'instance_state',
    numeric: true,
    disablePadding: false,
    label: 'Instance state',
  },
  {
    id: 'instance_type',
    numeric: true,
    disablePadding: false,
    label: 'CPU Cores',
  },
  {
    id: 'private_v4',
    numeric: true,
    disablePadding: false,
    label: 'Private IPv4',
  },
];

function EnhancedTableHead(props) {

  const tableRef = useRef();

  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };


  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all devices',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
 
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const numSelected = props.numSelected;

  const handleDeleteDevice = (rowData) => {
    let handles = rowData.map((device) => device.handle);
    return removeDevice(handles).then(() => {
      props.removeMultipleDevices(handles);
      props.setSelectedDevices([]);
      props.setSelected([]);
    });
  };


  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
      style={{justifyContent:'space-between'}}
    >
      {numSelected > 0 ? (
        <div>
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
          <Tooltip title="Terminate Instance">
          <IconButton onClick={() => {
          console.log(props.selectedDevices)
          props.openConfirmationModal({
            show: true,
            message:
              "Are you sure you want to permanently delete the selected devices?",
            onYes: () => {
              handleDeleteDevice(props.selectedDevices).then(
                props.openConfirmationModal({
                  show: false,
                  message: null,
                  onYes: function () {},
                })
              );
            },
          })
        }}>
            <DeleteIcon />
        </IconButton>
        </Tooltip>
        </Typography>
        </div>
      ) : (
        <div>
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          User Devices
          <Tooltip title="Filter list">
        <IconButton>
            <FilterListIcon />
        </IconButton>
        </Tooltip>
        </Typography>

        </div>
      )}

      {/* {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )} */}
        <Button
            style={{ height: "fit-content", backgroundColor:'#fc5001', fontWeight:'bold', color:'white'
        }}
            // color="primary"
            // color='white'
            variant="contained"
            // textColor="white"/
            // theme={theme}
            onClick={props.showModal}
        >
            Add Device
        </Button>
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function ConnectedEnhancedUserDeviceTable(props) {
  const tableRef = useRef();

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [rows, setRows] = useState([]);
  const [devices, setDevices] = useState([]);  
  const [installedApps, setInstalledApps] = useState([]);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [isCLIModalOpen, setCLIModal] = useState(false);
  const [deviceConnections, setDeviceConnections] = useState({});
  const [deviceInstallations, setDeviceInstallations] = useState({});
  const [allInstallations,setInstallations] = React.useState([]);
  const [deviceInfo, setDeviceInfo] = React.useState([]);
  const [isMoveDevicesModalOpen, setMoveDevicesModal] = React.useState(false);
  const [deviceHandles, setHandles] = useState([]);

  const classes = useStyles();
  
  useEffect(() => {
    props.addEventHandler("deviceTable", (message) => {
      if (
        message.event === "new_device" ||
        message.event === "remove_device" ||
        message.event === "edit_device" ||
        message.event === "connect_switch" ||
        message.event === "disconnect_switch" ||
        message.event === "edit_device_info" ||
        message.event === "update_install_state"
      ) {
        handleRefreshData();
      }
    });
    return () => props.removeEventHandler("deviceTable");
  }, [tableRef]);

  useEffect(() => {
    props.removeDevicesFromInstallation()
    getDevicesByProduct(null).then(res => {
      if (res && res.success) { 
        let newRows = []

        const foundDevices = res.devices.filter(device => device.is_vm === false);
        let devHandles =  foundDevices.map((device) => device.handle)

        if (deviceInfo !== null){
          getDeviceInfo(devHandles).then(res => setDeviceInfo(...deviceInfo,res.info)).then( () =>
            {
              foundDevices.forEach(function(device,i) {
                newRows.push(createData(i,device.name,device.handle,device.is_connected,device.cores,device.private_ip))
              })
              setRowsPerPage(newRows.length)
              setRows(newRows)
          }
        )
          getAllInstallations(devHandles).then(res => setInstallations(...allInstallations,res.installations))
        } else {
          getDeviceInfo(devHandles).then(res => setDeviceInfo(res.info)).then( () =>
            {
              foundDevices.forEach(function(device,i) {
                newRows.push(createData(i,device.name,device.handle,device.is_connected,device.cores,device.private_ip))
              })
              setRowsPerPage(newRows.length)
              setRows(newRows)
          }
        )
          getAllInstallations(devHandles).then(res => setInstallations(res.installations))
        }
        if (devices !== null){
          setDevices(...devices,foundDevices)
        } else {
          setDevices(foundDevices)
        }
      } else {
        setRowsPerPage(0)
        setRows([])
        setDevices({})
      }
    })
  }, [])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      var newSelectedDevs = []
      newSelected.forEach((id) => {
        newSelectedDevs.push(
          devices.filter(
            device => device.handle === visibleRows.filter(
              dev => dev.id === id
            )[0].instance_id
          )[0]
        )
      })

      let newDeviceInstallations = {}
      let newDeviceConnections = {}

      newSelectedDevs.forEach((device)=> {
        newDeviceInstallations[device.handle] = allInstallations.filter((app) => app.device === device.handle && app.state.toLowerCase() === "installed")
        newDeviceConnections[device.handle] = deviceInfo.filter((dev) => dev.handle === device.handle).map((info) => info.connections)
      })
      newDeviceInstallations = parseInstallations(newSelectedDevs.map((dev)=>dev.handle),newDeviceInstallations)
      newDeviceConnections = parseConnections(newSelectedDevs.map((dev)=>dev.handle),newDeviceConnections)

      setDeviceInstallations(newDeviceInstallations)
      setDeviceConnections(newDeviceConnections)


      props.removeDevicesFromInstallation()
      props.setDevicesToInstall(newSelectedDevs)
      setSelectedDevices(newSelectedDevs)
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const goToMarketplace = () => {
    props.history.push('/marketplace')
  }

  const goToSwitch = () => {
    props.history.push('/peergroups')
  }

  const handleShowClIModal = () => {
    setCLIModal(!isCLIModalOpen);
   }

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    var newSelectedDevs = []
    newSelected.forEach((id) => {
      newSelectedDevs.push(
        devices.filter(
          device => device.handle === visibleRows.filter(
            dev => dev.id === id
          )[0].instance_id
        )[0]
      )
    })
    
    let newDeviceInstallations = {}
    let newDeviceConnections = {}

    newSelectedDevs.forEach((device)=> {
      newDeviceInstallations[device.handle] = allInstallations.filter((app) => app.device === device.handle && app.state.toLowerCase() === "installed")
      newDeviceConnections[device.handle] = deviceInfo.filter((dev) => dev.handle === device.handle).map((info) => info.connections)
    })
    newDeviceInstallations = parseInstallations(newSelectedDevs.map((dev)=>dev.handle),newDeviceInstallations)
    newDeviceConnections = parseConnections(newSelectedDevs.map((dev)=>dev.handle),newDeviceConnections)

    setDeviceInstallations(newDeviceInstallations)
    setDeviceConnections(newDeviceConnections)

    props.removeDevicesFromInstallation()
    props.setDevicesToInstall(newSelectedDevs)
    setSelectedDevices(newSelectedDevs)
    setSelected(newSelected);
  };

  const handleRefreshData = () => {
    clearSelectedItems();
    getDevicesByProduct(null).then(res => {
      if (res && res.success) {
        let newRows = [] 
        const foundDevices = res.devices.filter(device => device.is_vm === false);
        let devHandles =  foundDevices.map((device) => device.handle)

        if (deviceInfo !== null){
          getDeviceInfo(devHandles).then(res => setDeviceInfo(...deviceInfo,res.info)).then( () =>
            {
              foundDevices.forEach(function(device,i) {
                newRows.push(createData(i,device.name,device.handle,device.is_connected,device.cores,device.private_ip))
              })
              setRowsPerPage(newRows.length)
              setRows(newRows)
          }
        )
          getAllInstallations(devHandles).then(res => setInstallations(...allInstallations,res.installations))
        } else {
          getDeviceInfo(devHandles).then(res => setDeviceInfo(res.info)).then( () =>
            {
              foundDevices.forEach(function(device,i) {
                newRows.push(createData(i,device.name,device.handle,device.is_connected,device.cores,device.private_ip))
              })
              setRowsPerPage(newRows.length)
              setRows(newRows)
          }
        )
          getAllInstallations(devHandles).then(res => setInstallations(res.installations))
        }
        if (devices !== null){
          setDevices(...devices,foundDevices)
        } else {
          setDevices(foundDevices)
        }
      } else {
        setRowsPerPage(0)
        setRows([])
        setDevices({})
      }
    })
  }

  const clearSelectedItems = (handles) => {
    props.removeDevicesFromInstallation();
    setSelectedDevices([]);
    setSelected([]);
  };
  
  const history = props.history;

  // const goToNewInstance = () => {
  //   history.push('/new_instance')
  // }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, rows],
  );

  const parseInstallations = (handles, installations) => {

    if (handles.length > 1) {
      let appsDict = {};

      for (let i = 0; i < handles.length; i++) {
        for (
          let n = 0;
          n < installations[handles[i]].length;
          n++
        ) {
          if (!appsDict[installations[handles[i]][n].name]) {
            appsDict[installations[handles[i]][n].name] = 1;
          } else {
            appsDict[installations[handles[i]][n].name]++;
          }
        }
      }
     
      if (Object.keys(appsDict)) {
        for (let key in appsDict) {
          if (appsDict.hasOwnProperty(key)) {
            if (appsDict[key] != handles.length) {
             delete appsDict[key];
              }
          }
        }
        for (let n = 0; n < Object.keys(installations).length; n++) {
         installations[Object.keys(installations)[n]] = installations[Object.keys(installations)[n]].filter((app) => Object.keys(appsDict).includes(app.name))
        }
        return installations;
      }
    }
    return installations;
  }

  const parseConnections = (handles, connections) => {

    if (handles.length > 1) {
      let switchDict = {};

      for (let i = 0; i < handles.length; i++) {
        for (
          let n = 0;
          n < connections[handles[i]].length;
          n++
        ) {
          if (!switchDict[connections[handles[i]][n].switch]) {
            switchDict[connections[handles[i]][n].switch] = 1;
          } else {
            switchDict[connections[handles[i]][n].switch]++;
          }
        }
      }
     
      if (Object.keys(switchDict)) {
        for (let key in switchDict) {
          if (switchDict.hasOwnProperty(key)) {
            if (switchDict[key] != handles.length) {
             delete switchDict[key];
              }
          }
        }
        for (let n = 0; n < Object.keys(connections).length; n++) {
         connections[Object.keys(connections)[n]] = connections[Object.keys(connections)[n]].filter((connection) => Object.keys(switchDict).includes(connection.switch))
        }
        return connections;
      }
    }
    return connections;
  }

  return (
    // <Box sx={{ width: '100%' }}>
    <div style={{height:'100vh'}}>

      <Grid container spacing={3}>
      <Grid item xs={12}>
      <div className={classes.bread}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          <Link color="inherit" component="button" variant="body2" onClick={() => {props.history.push('/')}}>
            Home
          </Link>
          <Link color="inherit"  component="button" variant="body2" onClick={() => {props.history.push('/rentnode')}}>
            Rent-a-Node
          </Link>
          <Typography color="textPrimary">User Devices</Typography>
        </Breadcrumbs>
      </div>
    <Paper sx={{ width: '100%', overflow: 'hidden', maxHeight: '35vh' }}>
          <EnhancedTableToolbar 
            numSelected={selected.length} history={history} selected={selected} 
            openConfirmationModal={props.openConfirmationModal} selectedDevices={selectedDevices} 
            setSelectedDevices={setSelectedDevices} setSelected={setSelected} removeMultipleDevices={props.removeMultipleDevices}
            showModal={handleShowClIModal}
          />            
           <TableContainer style={{ maxHeight: '35vh' }}>
          <Table
            stickyHeader
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      {row.name}
                    </TableCell>
                    <TableCell align="right">{row.instance_id}</TableCell>
                    {
                        (row.instance_state === true)
                            ? <TableCell align="right" style={{color:'#1d8102'}}><CheckCircleOutlineIcon fontSize="small" style={{verticalAlign: 'bottom', color:'#1d8102'}}/>{"Running"}</TableCell>
                            : <TableCell align="right" style={{color:'red'}}><HighlightOffIcon fontSize="small" style={{verticalAlign: 'bottom', color:'red'}}/>{"Stopped"}</TableCell>
                    }
                    <TableCell align="right">{row.instance_type}</TableCell>
                    <TableCell align="right">{row.private_v4}</TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={-1}
          component="div"
          count={rows.length}
          rowsPerPage={rows.length}
          page={page}
          // onChangePage={handleChangePage}
          // onChangeRowsPerPage={handleChangeRowsPerPage}
          on
        />
      </Paper>
      </Grid>
      {/* <Divider style={{backgroundColor:'black'}}></Divider> */}
      <Grid item xs={12} className={classes.resources}>
          <Paper className={classes.resources} style={{height:'100vh'}}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
              { selected.length === 1 ?
                <CardHeader style={{borderBottom: '1px solid #d5dbdb'}} titleTypographyProps={{variant:'h6', fontWeight:'bold' }} xs={12} title={visibleRows.filter(device => device.id === selected[0])[0].name} className={classes.header}></CardHeader> :
                selected.length >= 1 ?
                <CardHeader titleTypographyProps={{variant:'h6', fontWeight:'bold' }} xs={12} title="Metrics" className={classes.header}></CardHeader> :
                <CardHeader titleTypographyProps={{variant:'h6', fontWeight:'bold' }} xs={12} title="Select an instance" className={classes.header}></CardHeader>
              }
              </Grid>
              { selected.length === 1 ?
              <>
              <Grid item xs={12}>
                  <Accordion className={classes.heading} defaultExpanded>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      {/* <AlbumIcon color="secondary" style={{marginRight: "5px"}}/> */}
                      <Typography className={classes.heading} fontWeight="fontWeightBold">
                        Instance Summary
                      </Typography>
                      {/* <CardHeader titleTypographyProps={{variant:'h6' }} xs={12} title="OS Image" className={classes.heading} ></CardHeader> */}

                    </AccordionSummary>
                    <AccordionDetails style={{background:'white'}}>
                      <DeviceGeneralInfo
                        // toggleEditInfoModal={toggleEditInfoModal}
                        name={devices.filter(device => device.handle === visibleRows.filter(dev => dev.id === selected[0])[0].instance_id)[0].name}
                        handle={devices.filter(device => device.handle === visibleRows.filter(dev => dev.id === selected[0])[0].instance_id)[0].handle}
                        last_seen={devices.filter(device => device.handle === visibleRows.filter(dev => dev.id === selected[0])[0].instance_id)[0].last_seen}
                        mac={devices.filter(device => device.handle === visibleRows.filter(dev => dev.id === selected[0])[0].instance_id)[0].mac}
                        private_ip={devices.filter(device => device.handle === visibleRows.filter(dev => dev.id === selected[0])[0].instance_id)[0].private_ip}
                        status={devices.filter(device => device.handle === visibleRows.filter(dev => dev.id === selected[0])[0].instance_id)[0].status}
                        is_vm={devices.filter(device => device.handle === visibleRows.filter(dev => dev.id === selected[0])[0].instance_id)[0].is_vm}
                        ram={devices.filter(device => device.handle === visibleRows.filter(dev => dev.id === selected[0])[0].instance_id)[0].ram}
                        cores={devices.filter(device => device.handle === visibleRows.filter(dev => dev.id === selected[0])[0].instance_id)[0].cores}
                        sled_position={devices.filter(device => device.handle === visibleRows.filter(dev => dev.id === selected[0])[0].instance_id)[0].sled_position}
                        sled_number={devices.filter(device => device.handle === visibleRows.filter(dev => dev.id === selected[0])[0].instance_id)[0].sled_number}
                        product_type={devices.filter(device => device.handle === visibleRows.filter(dev => dev.id === selected[0])[0].instance_id)[0].product_type}
                        version={devices.filter(device => device.handle === visibleRows.filter(dev => dev.id === selected[0])[0].instance_id)[0].version}
                      />
                    </AccordionDetails>
                  </Accordion>
              </Grid>
              <Grid item xs={12}>
                  <Accordion className={classes.heading}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading} fontWeight="fontWeightBold">
                        Peer Groups
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{background:'white'}}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Button
                              style={{ height: "fit-content", backgroundColor:'#fc5001', fontWeight:'bold', color:'white'}}
                              // color="primary"
                              // color='white'
                              variant="contained"
                              // textColor="white"/
                              // theme={theme}
                              onClick={goToSwitch}
                          >
                              Join Peer Group
                          </Button>
                        </Grid>
                        <Grid item xs={12}>
                          <InstalledSwitches
                            connections={deviceInfo.filter(device => device.handle === visibleRows.filter(dev => dev.id === selected[0])[0].instance_id)[0].connections || []}
                            handle={devices.filter(device => device.handle === visibleRows.filter(dev => dev.id === selected[0])[0].instance_id)[0].handle}
                          />
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
              </Grid>
              <Grid item xs={12}>
                  <Accordion className={classes.heading}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading} fontWeight="fontWeightBold">
                        Applications
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{background:'white'}}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Button
                              style={{ height: "fit-content", backgroundColor:'#fc5001', fontWeight:'bold', color:'white'}}
                              // color="primary"
                              // color='white'
                              variant="contained"
                              // textColor="white"/
                              // theme={theme}
                              onClick={goToMarketplace}
                          >
                              Install Application
                          </Button>
                        </Grid>
                        <Grid item xs={12}>
                          <InstalledApps
                            apps={allInstallations.filter(app => selectedDevices.map((device)=> device.handle).includes(app.device)) || []}
                            clearSelectedItems={clearSelectedItems}
                            // handle={devices.filter(device => device.handle === visibleRows.filter(dev => dev.id === selected[0])[0].instance_id)[0].handle}
                          />
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
              </Grid>
              </> : <></>}
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
        </Grid>
      </Grid>
      <CLIModal show={isCLIModalOpen} handleClose={() => setCLIModal(false)} />
      {/* <TemporaryDrawer></TemporaryDrawer> */}
    </div>

    // </Box>
  );
}

const EnhancedUserDeviceTable = connect(
    mapStateToProps, mapDispatchToProps
  )(ConnectedEnhancedUserDeviceTable);
  
  export default EnhancedUserDeviceTable;

import * as React from 'react';
import { useState, useRef, useEffect } from 'react';

import PropTypes from 'prop-types';
import { alpha } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
 
import { connect } from "react-redux";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import { getDevicesByProduct } from '../utils/Cachengo';
import ErrorIcon from '@material-ui/icons/Error';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import TemporaryDrawer from './TemporaryDrawer';
import Grid from '@material-ui/core/Grid';
import CardHeader from '@material-ui/core/CardHeader';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AlbumIcon from '@material-ui/icons/Album';
import DeviceGeneralInfo from './DeviceGeneralInfo';
import { setDevicesToInstall } from '../actions/installation';
import { removeEventHandler, addEventHandler } from '../actions/items';

import { getOrgKeys, removeKey } from '../utils/Cachengo';
import AddKeyModal from './modals/AddKeyModal';

import {
  setConfirmationModalOpen,
  setSnackBarMessage,
  displayRequestError,
} from "../actions/interactions";

const mapStateToProps = (state) => {
    return {
      payInfo: state.portal.payInfo || {},
      org: state.portal.selectedOrg,
    };
};

function mapDispatchToProps(dispatch) {
  return {
    removeEventHandler: (key) => dispatch(removeEventHandler(key)),
    addEventHandler: (key, handler) => dispatch(addEventHandler(key, handler)),
    openConfirmationModal: (item) => dispatch(setConfirmationModalOpen(item)),
    displayRequestError: (res) => dispatch(displayRequestError(res))
  }
}

const useStyles = makeStyles((theme) => ({
  bread:{
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    paper: {
      padding: theme.spacing(2),
      // textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    resources: {
      padding: theme.spacing(1),
      // textAlign: 'center',
      color: theme.palette.text.secondary,
      height: '100vh',
    },
    header: {
      textAlign: 'center',
      justifyContent: 'center',
      // padding: theme.spacing(2),
      width: '100%',
      textAlign: 'left',
      background: '#eaeded',
      color: 'black',
      fontWeight: 'bold',
      border: '1.5px solid black'
    },
    card: {
      border: '1px solid #d5dbdb',
      // maxHeight: '4vh'
      // borderRadius: '1px',
      // borderWeight: 'light',
    },
  }
}));

function createData(id, name, instance_id, key_type) {
  return {
    id,
    name,
    instance_id,
    key_type,
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
  },
  {
    id: 'key_id',
    numeric: true,
    disablePadding: false,
    label: 'Key ID',
  },
  {
    id: 'key_type',
    numeric: true,
    disablePadding: false,
    label: 'Type',
  },
];

function EnhancedTableHead(props) {

  const tableRef = useRef();

  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };


  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all keys',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
 
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const numSelected = props.numSelected;
//   const theme = createTheme({
//     palette: {
//         primary: {
//         light: '#fc5001',
//         main: '#fc5001',
//         dark: '#fc5001',
//         contrastText: '#fff',
//         },
//     },
//   });
//   let theme = createTheme()

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
      style={{justifyContent:'space-between'}}
    >
      {numSelected > 0 ? (
        <div>
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
          <Tooltip title="Delete">
        <IconButton>
            <DeleteIcon  onClick={props.removeKey} />
        </IconButton>
        </Tooltip>
        </Typography>
        </div>
      ) : (
        <div>
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Public Keys
          <Tooltip title="Filter list">
        <IconButton>
            <FilterListIcon />
        </IconButton>
        </Tooltip>
        </Typography>

        </div>
      )}

      {/* {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )} */}
        {/* <Button
            style={{ height: "fit-content", backgroundColor:'#fc5001', fontWeight:'bold', color:'white'
        }}
            // color="primary"
            // color='white'
            variant="contained"
            // textColor="white"/
            // theme={theme}
            onClick={props.removeKey}
        >
            Delete Public Key
        </Button> */}
        <Button
            style={{ height: "fit-content", backgroundColor:'#fc5001', fontWeight:'bold', color:'white'
        }}
            // color="primary"
            // color='white'
            variant="contained"
            // textColor="white"/
            // theme={theme}
            onClick={props.toggleModal}
        >
            New Public Key
        </Button>
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function ConnectedEnhancedKeyTable(props) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [rows, setRows] = useState([]);
  const [keys, setKeys] = useState([]);
  const tableRef = React.useRef()
  const [isModalOpen, setIsModalOpen] = useState(false) 
  
  useEffect(() => {
    props.addEventHandler('keyTable', (message) => {
      if (message.event === 'add_key' || message.event === 'remove_key') {
        refreshKeys()
        refreshTable()
      }
    })
    return () => props.removeEventHandler('keyTable')
  }, [tableRef])

  const classes = useStyles();
  
  const toggleAddUserModal = event => {
    setIsModalOpen(!isModalOpen)
  } 

  const refreshTable = () => {
    tableRef.current && tableRef.current.onQueryChange()
  }

  useEffect(() => {
    getOrgKeys().then(res => {
      if (res && res.success) { 
        const keys = res.public_keys;
        // const devices = testData.filter(device => device.is_vm === true);
        // console.log(res)
        setKeys(res)
        // console.log(devices)
        let newRows = []
        keys.forEach(function(key,i) {
          newRows.push(createData(i,key.name,key.handle,"RSA"))
        })
        // console.log(newRows)
        setRowsPerPage(newRows.length)
        setRows(newRows)
      } else {
        setRowsPerPage(0)
        setRows([])
      }
    })
  }, [])

  const refreshKeys = (message) =>{
    getOrgKeys().then(res => {
      if (res && res.success) { 
        const keys = res.public_keys;
        // const devices = testData.filter(device => device.is_vm === true);
        setKeys(res)
        // console.log(devices)
        let newRows = []
        keys.forEach(function(key,i) {
          newRows.push(createData(i,key.name,key.handle,"RSA"))
        })
        setRowsPerPage(newRows.length)
        setRows(newRows)
      } else {
        setRowsPerPage(0)
        setRows([])
      }
    })
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleRemoveKey = () => {
    const handles = selected.map(function(item) { return visibleRows.filter(key => key.id === item)[0].instance_id });
    removeKey(handles, props.displayRequestError)
    .then(res => {
      refreshTable()
    }) 
  }

  const history = props.history;

  // const goToNewInstance = () => {
  //   history.push('/new_instance')
  // }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, rows],
  );
  return (
    // <Box sx={{ width: '100%' }}>
    <div style={{height:'100vh'}}>

      <Grid container spacing={3}>
      <Grid item xs={12}>
      <div className={classes.bread}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          <Link color="inherit" component="button" variant="body2" onClick={() => {props.history.push('/')}}>
            Home
          </Link>
          <Link color="inherit"  component="button" variant="body2" onClick={() => {props.history.push('/rentnode')}}>
            Rent-a-Node
          </Link>
          <Typography color="textPrimary">Public Keys</Typography>
        </Breadcrumbs>
      </div>
    <Paper sx={{ width: '100%', overflow: 'hidden', maxHeight: '35vh' }}>
        <EnhancedTableToolbar numSelected={selected.length} history={history} toggleModal={toggleAddUserModal} removeKey={handleRemoveKey} />
        <TableContainer style={{ maxHeight: '35vh' }}>
          <Table
            stickyHeader
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      {row.name}
                    </TableCell>
                    <TableCell align="right">{row.instance_id}</TableCell>
                    <TableCell align="right">{row.key_type}</TableCell>
    
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={-1}
          component="div"
          count={rows.length}
          rowsPerPage={rows.length}
          page={page}
          // onChangePage={handleChangePage}
          // onChangeRowsPerPage={handleChangeRowsPerPage}
          on
        />
      </Paper>
      </Grid>
      </Grid>
      {/* <Divider style={{backgroundColor:'black'}}></Divider> */}
 
      {/* <TemporaryDrawer></TemporaryDrawer> */}
      <AddKeyModal 
          org={props.org.handle}
          show={isModalOpen}
          handleClose={toggleAddUserModal}
          refreshTable={refreshTable}
      />
    </div>

    // </Box>
  );
}

const EnhancedKeyTable = connect(
    mapStateToProps, mapDispatchToProps
  )(ConnectedEnhancedKeyTable);
  
export default EnhancedKeyTable;

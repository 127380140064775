/*
 Copyright 2019, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at
 
 http://www.apache.org/licenses/LICENSE-2.0
 
 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */

import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { setForRent } from '../../utils/Cachengo';
import { runOnEnter } from '../../utils/Helpers';
import TextField from '@material-ui/core/TextField';
import NumberFormatCustom from '../NumberFormatCustom'
import CircularProgress from "@material-ui/core/CircularProgress";


export default class NewSwitchModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      price: "0.00",
      initial_fee: "0.00",
      isLoading: false,
      reservation_handle: "",
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange = price => event => {
    const value = event.target.value;
    
    this.setState({
      [price]: value
    });
  }

  handleSubmit() {
    var parsedValue = parseFloat(this.state.price)
    var parsedInitialFeeValue = parseFloat(this.state.initial_fee);
    var trimmedReservation = this.state.reservation_handle.trim();
    // this.setState({[this.state.isLoading]: true});
    setForRent(this.props.handles, true, parsedValue, parsedInitialFeeValue, trimmedReservation)
      .then(res => {
        this.props.clearSelectedItems(this.props.handles)
        this.props.handleClose();
        // this.setState({[this.state.isLoading]: false})    
      })
  }

  render() {
    return (
      <Dialog
        open={this.props.show}
        onClose={this.props.handleClose}
        aria-labelledby="newswitch-dialog-title"
        fullWidth
      >
        <DialogTitle id="newswitch-dialog-title">Set Price for Rental</DialogTitle>
        <DialogContent>
          {/* <TextField
            label="Set a price for the rental"
            value={this.state.price}
            onChange={this.handleInputChange('price')}
            name="numberformat"
            id="formatted-numberformat-input"
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
            onKeyPress={runOnEnter(this.handleSubmit)}
            fullWidth
            color='secondary'
          />
          <TextField
            label="Set a price for the initial setup fee"
            value={this.state.initial_fee}
            onChange={this.handleInputChange('initial_fee')}
            name="numberformat"
            id="formatted-numberformat-input"
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
            onKeyPress={runOnEnter(this.handleSubmit)}
            fullWidth
            color='secondary'
          /> */}
          <TextField
            label="Organization Handle for Reservation"
            value={this.state.reservation_handle}
            onChange={this.handleInputChange('reservation_handle')}
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
            onKeyPress={runOnEnter(this.handleSubmit)}
            fullWidth
            color='secondary'    
            autoFocus
            margin="dense"
            type="text"
            placeholder="Input org handle"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleSubmit}>Submit</Button>
          <Button onClick={this.props.handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

/*
 Copyright 2019, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at
 
 http://www.apache.org/licenses/LICENSE-2.0
 
 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */

 import React, { useState } from 'react';
 import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography } from '@material-ui/core';

import { setConfirmationModalOpen } from '../../actions/interactions';
import { connect } from 'react-redux';

import { setForRent } from '../../utils/Cachengo';
import { runOnEnter } from '../../utils/Helpers';
import TextField from '@material-ui/core/TextField';
import NumberFormatCustom from '../NumberFormatCustom'
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from '@material-ui/core/Box';

const SetRentModal = props => {

  const [isLoading, setIsLoading] = useState(false);
  const [price, setPrice] = useState('0.00');
  const [reservationHandle, setReservationHandle] = useState('');

  const handleInputChange = (e) => {
    setReservationHandle(e.target.value)
  }

  const handleSubmit = () => {
    var parsedValue = parseFloat(price)
    var parsedInitialFeeValue = parseFloat("0.00");
    var trimmedReservation = reservationHandle.trim();
    // this.setState({[this.state.isLoading]: true});
    setIsLoading(true)
    setForRent(props.handles, true, parsedValue, parsedInitialFeeValue, trimmedReservation)
      .then(res => {
        props.clearSelectedItems(props.handles)
        props.handleClose();
        setIsLoading(false)
        // this.setState({[this.state.isLoading]: false})    
      }) 
  }

  return (
    <Dialog
    open={props.show}
    onClose={props.handleClose}
    aria-labelledby="newswitch-dialog-title"
    fullWidth
  >
    <DialogTitle id="newswitch-dialog-title">Set Organization Handle for Reservation</DialogTitle>
    { !isLoading ?
    <DialogContent>
      {/* <TextField
        label="Set a price for the rental"
        value={price}
        onChange={handleInputChange}
        name="numberformat"
        id="formatted-numberformat-input"
        InputProps={{
          inputComponent: NumberFormatCustom,
        }}
        onKeyPress={runOnEnter(handleSubmit)}
        fullWidth
        color='secondary'
      /> */}
      {/* <TextField
        label="Set a price for the initial setup fee"
        value={this.state.initial_fee}
        onChange={this.handleInputChange('initial_fee')}
        name="numberformat"
        id="formatted-numberformat-input"
        InputProps={{
          inputComponent: NumberFormatCustom,
        }}
        onKeyPress={runOnEnter(this.handleSubmit)}
        fullWidth
        color='secondary'
      /> */}
          <TextField
            label="Organization Handle for Reservation"
            value={reservationHandle}
            onChange={handleInputChange}
            onKeyPress={runOnEnter(handleSubmit)}
            fullWidth
            color='secondary'    
            autoFocus
            margin="dense"
            type="text"
            placeholder="Input org handle"
          />
    </DialogContent>
    :
    <DialogContent>
      <Box sx={{ display: "flex" }}>
        <CircularProgress />
      </Box>
    </DialogContent>
}
    <DialogActions>
      <Button onClick={handleSubmit}>Submit</Button>
      <Button onClick={props.handleClose}>Close</Button>
    </DialogActions>
  </Dialog>
  )
}

export default SetRentModal;

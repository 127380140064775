/*
 Copyright 2019, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

 http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */
 import React, { useState, useRef, useEffect } from 'react';
 import { connect } from "react-redux";
 import Grid from '@material-ui/core/Grid';
 import ListItemText from "@material-ui/core/ListItemText";
 import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
 import DevicesIcon from '@material-ui/icons/Devices';
 import Typography from '@material-ui/core/Typography';
 import Button from '@material-ui/core/Button';
 import { addEventHandler, removeEventHandler } from '../actions/items';
 import { makeStyles } from '@material-ui/core/styles';
 import Breadcrumbs from '@material-ui/core/Breadcrumbs';
 import NavigateNextIcon from '@material-ui/icons/NavigateNext';
 import Link from '@material-ui/core/Link';
 import CardHeader from '@material-ui/core/CardHeader';
 import Paper from '@material-ui/core/Paper';
 import TextField from '@material-ui/core/TextField';
 import Accordion from '@material-ui/core/Accordion';
 import AccordionSummary from '@material-ui/core/AccordionSummary';
 import AccordionDetails from '@material-ui/core/AccordionDetails';
 import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
 import AppsIcon from '@material-ui/icons/Apps';
 import AlbumIcon from '@material-ui/icons/Album';
 import VpnKeyIcon from '@material-ui/icons/VpnKey';
 import MemoryIcon from '@material-ui/icons/Memory';
 import InputLabel from '@material-ui/core/InputLabel';
 import MenuItem from '@material-ui/core/MenuItem';
 import FormControl from '@material-ui/core/FormControl';
 import Select from '@material-ui/core/Select';
 import CircularProgress from "@material-ui/core/CircularProgress";
 import Box from '@material-ui/core/Box';
 
 import { getOrgKeys, fetchAvailableInstances, installKey, doRent, createCluster, connectCluster } from '../utils/Cachengo';
 
 
 const mapStateToProps = (state) => {
     return {
       payInfo: state.portal.payInfo || {},
     };
 };
 
 function mapDispatchToProps(dispatch) {
   return {
     removeEventHandler: (key) => dispatch(removeEventHandler(key)),
     addEventHandler: (key, handler) => dispatch(addEventHandler(key, handler)),
   }
 }
 
 const useStyles = makeStyles((theme) => ({
   root: {
     flexGrow: 1,
   },
   paper: {
     padding: theme.spacing(2),
     color: theme.palette.text.secondary,
   },
   resources: {
     padding: theme.spacing(1.5),
     color: theme.palette.text.secondary,
     minHeight: '12vh',
   },
   heading: {
     textAlign: 'center',
     justifyContent: 'center',
     width: '100%',
     textAlign: 'left',
     background: '#eaeded',
     color: 'black',
     margin:0,
     padding:0
   },
   border: {
       boxShadow: "None"
   },
   metricsGrid:{
     [theme.breakpoints.up('sm')]: {
       margin: "10px", 
       padding: "10px"
     },
   },
   card: {
     border: '1px solid #d5dbdb',
   },
   divLeft: {
     display:'inline',
     float: 'left'
   },
   divRight: {
     display:'inline',
     float: 'right',
     justifyContent:'right'
   },
   header: {
     textAlign: 'center',
     justifyContent: 'center',
     width: '100%',
     textAlign: 'left',
     background: '#eaeded',
     color: 'black'
   },
   title: {
     textAlign: 'center',
     justifyContent: 'center',
     width: '100%',
     textAlign: 'left',
     color: 'black'
   },
   bread:{
     '& > * + *': {
       marginTop: theme.spacing(2),
     },
   }
 }));
 
 function ConnectedNewClusterForm (props) {
   const tableRef = useRef();
   const [isNewRentModalOpen, setNewRentmodal] = useState(false)
   const [deviceHandles, setDeviceHandles] = useState([]);  
   const classes = useStyles();
   const [osImage, setOsImage] = React.useState(0);
   const [osImageName, setOsImageName] = React.useState("ubuntu-jammy-22.04-arm64-server");
   const [publicKey, setPublicKey] = React.useState('');
   const [instanceType, setInstanceType] = React.useState(0);
   const [keys, setKeys] = React.useState([]);
   const [availableDevices, setAvailableDevices] = useState([]);
   const [instanceName, setInstanceName] = useState('');
   const [serverNum, setServerNum] = useState(3);  
   const [agentNum, setAgentNum] = useState(5);
   const [isLoading, setIsLoading] = useState(false);  
 
   const  handleSubmit = ()=> {
     setIsLoading(true)

     console.log(instanceName)
     var online_devices = availableDevices.filter(device => device.is_connected === true);
     var num_devices = agentNum+serverNum
     console.log(online_devices)
     var online_device_handles = []
     online_devices.forEach((device) => {
       online_device_handles.push(device.handle)
     })
     var device_handles = online_device_handles.slice(0,num_devices)
     console.log(online_device_handles)
     console.log(device_handles)
     var vm_handles = []
     var cluster_handle = ""
     // createCluster(instanceName, "")
     const deviceNames = device_handles.map((dev,i) => 
       instanceName+"-"+i
     )
     doRent(device_handles,deviceNames)
         .then(res => {
           vm_handles = res.vm_handles
           createCluster(instanceName, "")
           .then(res => {
             console.log(res)
             cluster_handle = res.cluster_id
             console.log(cluster_handle)
             console.log(vm_handles)
             connectCluster(vm_handles,cluster_handle,serverNum,agentNum)
           })
         }) 
         .then(res => {
           installKey(vm_handles,[publicKey])
         })
         .then(() => props.history.push('/rentnode'))
 
         .catch((err) => {
           console.log('Failed')
           console.log(err)
           props.history.push('/rentnode')
         });
 
         // .then(res => {
         //   cluster_handle = res.cluster_id
         //   connectCluster(vm_handles.slice(serverNum,num_devices),cluster_handle,false)
         // })
         // .then(res => {
         //   installKey(vm_handles,publicKey)
         // })
         // .then(() => props.history.push('/my_clusters'))
         // .catch(() => props.history.push('/my_clusters'));
   }
 
   const handleChange = (event) => {
     setPublicKey(event.target.value);
   };
 
   const handleChangeOS = (event) => {
     setOsImage(event.target.value)
     event.target.value === 0 ? setOsImageName("ubuntu-jammy-22.04-arm64-server") : setOsImageName("")
   }
 
   const handleChangeInstance = (event) => {
     setInstanceType(event.target.value)
   }
   
 
   const handleInputChange = (event) => {
     const target = event.target;
     const value = target.value;
 
     setInstanceName(value)
   }
 
   const handleServerInputChange = (event) => {
     const target = event.target;
     const value = target.value;
 
     setServerNum(parseInt(value, 10))
   }
 
   const handleAgentInputChange = (event) => {
     const target = event.target;
     const value = target.value;
 
     setAgentNum(parseInt(value, 10))
   }
 
   const buyer = props.payInfo.buyer || {};
   const canBuy = buyer.can_buy || false; 
 
   useEffect(() => {
     getKeys()
     getAvailableRentals()
 
   }, [])
 
   const clearSelectedItems = () => {
     tableRef.current && tableRef.current.clearSelectedItems()
   }
 
   const getKeys = () =>{
     getOrgKeys().then(res => {
       if (res && res.success) { 
         // const devices = testData.filter(device => device.is_vm === true);
         setKeys(res.public_keys)
         console.log(res.public_keys)
         // console.log(devices)
       } else {
         setKeys([])
       }
     })
   }
 
   const getAvailableRentals = () =>{
     fetchAvailableInstances({}).then(res => {
       if (res && res.success) { 
         // const devices = testData.filter(device => device.is_vm === true);
         // setKeys(res.public_keys)
         console.log(res)
         setAvailableDevices(res.data)
         // console.log(devices)
       } else {
         // setKeys([])
         console.log('failed')
         setAvailableDevices([])
       }
     })
   }
   
   const toggleRentModal = (rowData) => {
     if ( isNewRentModalOpen ){
       setNewRentmodal( !isNewRentModalOpen )
     } else {
       setNewRentmodal(!isNewRentModalOpen);
       let handles = rowData.map(device => {
         return {handle: device.handle, name: device.name}
       });
       setDeviceHandles(handles)
     }
   }
 
   const refreshData = () => {
     tableRef.current && tableRef.current.refreshTable()
   }
 
   const deviceIcon = props => {
     return (
       <DevicesIcon {...props} style={{ margin: '-4px 8px 0 0' }} color='secondary' />
     )
   };
   const columnHead = {
     field: 'name', icon: deviceIcon
   }
   const columns = [
     {
       render: rowData => {
         return (
           <Grid container>
             <Grid item xs={6}>
               <Typography variant='body2' >
                 Price: ${rowData.price}
               </Typography>
             </Grid>
             <Grid item xs={6}>
               <Typography variant='body2' >
                 Cores: {rowData.cores}
               </Typography>
             </Grid>
             <Grid item xs={6}>
               <Typography variant='body2' >
                 ARCH: {rowData.arch}
               </Typography>
             </Grid>
             <Grid item xs={6}>
               <Typography variant='body2' >
                 RAM: {parseFloat((rowData.ram / Math.pow(1024, 3)).toFixed(1))} GB
             </Typography>
             </Grid>
             <Grid item xs={6}>
               <Typography variant='body2' >
                 Location: {rowData.city}, {rowData.province}. {rowData.country}
               </Typography>
             </Grid>
             <Grid item xs={6}>
               <Typography variant='body2' >
                 Accelerator: {rowData.accelerator}
               </Typography>
             </Grid>
           </Grid>
         )
       }
     }
   ]
 
   const shoppingCartIcon = (props) => (
     <ShoppingCartIcon {...props} />
   );
 
   const actions = [
     {
       icon: shoppingCartIcon,
       tooltip: 'Rent-a-Node',
       onClick: (event, rowData) => {
         toggleRentModal(rowData)
       },
     },
   ]
 
   return (
     // <div>
     // {canBuy ? 
     // <Grid style={{paddingTop: '20px'}}>
     //     <CustomTable
     //         ref={tableRef}
     //         multiSelect={true}
     //         title='Rent-a-Node'
     //         getData={fetchAvailableRentals}
     //         actions={actions}
     //         columns={columns}
     //         columnHead={columnHead}
     //         errMessage={'Problem fetching rental devices'}
     //     />
     //     <NewRentModal 
     //         show={isNewRentModalOpen}
     //         handleClose={toggleRentModal}
     //         handles={deviceHandles}
     //         clearSelectedItems={clearSelectedItems}
     //         refreshTable={refreshData}
     //     />
     //     </Grid>
     // :
     // <Grid style={{paddingTop: '20px'}}>
     //     <Typography>
     //         To activate node rental capabilities, 
     //         please enter your payment information in the "Balances" section.
     //     </Typography>
     // </Grid> 
     // }   
     // </div>
   <div className={classes.root}>
     <Grid container spacing={1}>
       <Grid item xs={12}>
       <div className={classes.bread}>
       <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
         <Link color="inherit" component="button" variant="body2" onClick={() => {props.history.push('/')}}>
           Home
         </Link>
         <Link color="inherit"  component="button" variant="body2" onClick={() => {props.history.push('/rentnode')}}>
           Rent-a-Node
         </Link>
         <Link color="inherit"  component="button" variant="body2" onClick={() => {props.history.push('/my_clusters')}}>
           Clusters
         </Link>
         <Typography color="textPrimary">Create Cluster</Typography>
       </Breadcrumbs>
     </div>
       </Grid>
       <Grid item xs={12}>
       <CardHeader titleTypographyProps={{variant:'h4' }} xs={12} title="Launch a cluster" className={classes.title} subheader="Rent-a-Node allows you to create k3s clusters of instances, which are hosted by RNOs (Rent-a-Node Operators). Quickly get started by following the simple steps below."></CardHeader>
 
       </Grid>
       <Grid item xs={12}>
         <Paper className={classes.resources}>
           <Grid container spacing={3}>
             <CardHeader titleTypographyProps={{variant:'h6' }} xs={12} title="Name" className={classes.header}></CardHeader>
             <TextField
           id="standard-full-width"
           label="Name"
           style={{ margin: 8 }}
           placeholder="My Cluster"
           helperText="Give your cluster a descriptive name."
           fullWidth
           margin="normal"
           variant="outlined"
           InputLabelProps={{
             shrink: true,
           }}
           onChange={handleInputChange}
         />
           </Grid>
         </Paper>
         <div style={{ paddingTop: "10px" }}>
         <Accordion className={classes.heading} defaultExpanded>
           <AccordionSummary
             expandIcon={<ExpandMoreIcon />}
             aria-controls="panel1a-content"
             id="panel1a-header"
           >
             <AlbumIcon color="secondary" style={{marginRight: "5px"}}/>
             <Typography className={classes.heading} fontWeight="fontWeightBold">
               OS Image
             </Typography>
           </AccordionSummary>
           <AccordionDetails style={{background:'white'}}>
                   <FormControl fullWidth>
                     <InputLabel id="demo-simple-select-label">OS Image</InputLabel>
                     <Select
                       labelId="demo-simple-select-label"
                       id="demo-simple-select"
                       value={osImage}
                       label="OS Image"
                       onChange={handleChangeOS}
                     >
                       <MenuItem value={0}>ubuntu-jammy-22.04-arm64-server</MenuItem>
                     </Select>
                   </FormControl>
           </AccordionDetails>
         </Accordion>
       </div>
       <div style={{ paddingTop: "10px" }}>
         <Accordion className={classes.heading} defaultExpanded>
           <AccordionSummary
             expandIcon={<ExpandMoreIcon />}
             aria-controls="panel1a-content"
             id="panel1a-header"
           >
             <MemoryIcon color="secondary" style={{marginRight: "5px"}}/>
             <Typography className={classes.heading} fontWeight="fontWeightBold">
               Instance Type
             </Typography>
           </AccordionSummary>
           <AccordionDetails style={{background:'white'}}>
               <FormControl fullWidth>
                 <InputLabel id="demo-simple-select-label">Instance Type</InputLabel>
                 <Select
                   labelId="demo-simple-select-label"
                   id="demo-simple-select"
                   value={instanceType}
                   label="Instance Type"
                   onChange={handleChangeInstance}
                 >
                   <MenuItem value={0} style={{whiteSpace: 'normal'}}>
                     { availableDevices.length > 0 ?
                     <div style={{ display: 'flex', flexDirection: 'column' }}>
                       <span><strong>Venom</strong></span>
                       <span>8 CPU 16 GiB Memory</span>
                       <span>{availableDevices.length} Instances Available</span>
                     </div>
                     : 
                     <div style={{ display: 'flex', flexDirection: 'column' }}>
                       <span><strong>No Available Instances</strong></span>
                     </div>
                     }
                   </MenuItem>
                 </Select>
               </FormControl>
 
           </AccordionDetails>
         </Accordion>
       </div>
       {/* <Divider style={{ margin: "10px" }}/> */}
       <div style={{ paddingTop: "10px" }}>
         <Accordion className={classes.heading} defaultExpanded>
           <AccordionSummary
             expandIcon={<ExpandMoreIcon />}
             aria-controls="panel1a-content"
             id="panel1a-header"
           >
             <VpnKeyIcon color="secondary" style={{marginRight: "5px"}}/>
             <Typography className={classes.heading}>
               Public Key
             </Typography>
           </AccordionSummary>
           <AccordionDetails style={{background:'white'}}>
               <FormControl fullWidth>
                 <InputLabel id="demo-simple-select-label">Select a public key to be installed on all instances in the cluster.</InputLabel>
                 <Select
                   labelId="demo-simple-select-label"
                   id="demo-simple-select"
                   value={publicKey}
                   label="Public Key"
                   onChange={handleChange}
                   placeholder="Select a public key to be installed on all instances in the cluster."
                 >
                 { keys.map((key) => {
                   return(
                     <MenuItem value={key.handle}>{key.name}</MenuItem>
                   );
                   })
                 }
                 </Select>
               </FormControl>
 
           </AccordionDetails>
         </Accordion>
       </div>
       <div style={{ paddingTop: "10px" }}>
         <Accordion className={classes.heading}>
           <AccordionSummary
             expandIcon={<ExpandMoreIcon />}
             aria-controls="panel1a-content"
             id="panel1a-header"
           >
             <VpnKeyIcon color="secondary" style={{marginRight: "5px"}}/>
             <Typography className={classes.heading}>
               Advanced
             </Typography>
           </AccordionSummary>
           <AccordionDetails style={{background:'white'}}>
               <FormControl fullWidth>
                 {/* <InputLabel id="demo-simple-select-label">Select a Public Key</InputLabel>
                 <Select
                   labelId="demo-simple-select-label"
                   id="demo-simple-select"
                   value={publicKey}
                   label="Servers"
                   onChange={handleChange}
                   placeholder="Select a Public Key"
                 >
                 { keys.map((key) => {
                   return(
                     <MenuItem value={key.handle}>{key.name}</MenuItem>
                   );
                   })
                 }
                 </Select> */}
                 <TextField
                   id="standard-full-width"
                   label="Servers"
                   style={{ margin: 8 }}
                   placeholder="3"
                   helperText="Input desired number of servers. These are nodes on the control plane. An odd number of at least 3 is recommended for High Availability."
                   fullWidth
                   margin="normal"
                   variant="outlined"
                   InputLabelProps={{
                     shrink: true,
                   }}
                   onChange={handleServerInputChange}
                 />
                 <TextField
                   id="standard-full-width"
                   label="Agents"
                   style={{ margin: 8 }}
                   placeholder="5"
                   helperText="Input desired number of agents. These are kubernetes worker nodes not on the control plane."
                   fullWidth
                   margin="normal"
                   variant="outlined"
                   InputLabelProps={{
                     shrink: true,
                   }}
                   onChange={handleAgentInputChange}
                 />
               </FormControl>
 
           </AccordionDetails>
         </Accordion>
       </div>
       <div style={{ paddingTop: "10px" }}>
         <Accordion className={classes.heading} defaultExpanded>
           <AccordionSummary
             expandIcon={<ExpandMoreIcon />}
             expa
             aria-controls="panel1a-content"
             id="panel1a-header"
           >
             <AppsIcon color="secondary" style={{marginRight: "5px"}}/>
             <Typography className={classes.heading}>
               Summary
             </Typography>
           </AccordionSummary>
           <AccordionDetails style={{background:'white'}}>
             <Grid container spacing={2}>
               <Grid item xs={12}>
                 <ListItemText secondary={osImageName} primary="OS Image" />
               </Grid>
               <Grid item xs={12}>
                 <ListItemText secondary={serverNum+agentNum} primary="Instances" />
               </Grid>
               { availableDevices.length > 0 ?
               <Grid item xs={12}>
                 <ListItemText secondary="venom" primary="Virtual Server Type" />
               </Grid>
               :
               <Grid item xs={12}>
                 <ListItemText secondary="No available instances" primary="Virtual Server Type" />
               </Grid>
               }
               { availableDevices.length > 0 ?
               <Grid item xs={12}>
                 <ListItemText secondary={`$0/month x ${serverNum+agentNum}`} primary="Price" />
               </Grid>
               :
               <Grid item xs={12}>
                 <ListItemText secondary={`$0/month x ${serverNum+agentNum}`} primary="Price" />
               </Grid>
               }
               <Grid item xs={12}>
                 {
                   availableDevices.length >= agentNum+serverNum && instanceName !== "" && !isLoading ? 
                   <Button
                   style={{ height: "fit-content", backgroundColor:'#fc5001', fontWeight:'bold', color:'white'
                   }}
                     variant="contained"
                     onClick={handleSubmit}
                 >
 
                   Create Cluster
                 </Button>
                  : isLoading ?
                  <>
                  <Box sx={{ display: "flex" }}>
                    <CircularProgress />
                  </Box>
                  </>
                  :
                   <Button
                   style={{ height: "fit-content", backgroundColor:'gray', fontWeight:'bold', color:'white'
                   }}
                     variant="contained"
                     disabled
                 >
                   Create Cluster
                 </Button>
                 }
               </Grid>
             </Grid>
 
           </AccordionDetails>
         </Accordion>
       </div>
       </Grid>
     </Grid>
   </div>
   );
 }
 
 const NewCluster = connect(
   mapStateToProps, mapDispatchToProps
 )(ConnectedNewClusterForm);
 
 export default NewCluster;

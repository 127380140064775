/*
 Copyright 2019, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

 http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */

import React from "react";
import { useHistory, useParams } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from "react-redux";

import Shell from "./Shell";


const mapStateToProps = (state) => {
  return {
    currentUser: state.portal.currentUser,
  };
};

function ConnectedTerminal(props) { 
  const { id, name } = useParams();
  const history = useHistory()
  const goBack = () => {
    // if(props.location.isInternal){
    //   history.goBack()
    // }else{
    //   history.push('/')
    // }
    history.goBack()
  }
  console.log(history)

  return (
    <Grid style={{ marginTop: "10px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6">Device Shell</Typography>
        <div>
          <IconButton onClick={goBack} aria-label="close-btn">
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <Shell 
        handle={id}
        name={name}
        username={props.currentUser.username}
        jwt={props.currentUser.jwt}
      />
    </Grid>
  );
}

const Terminal = connect(
  mapStateToProps,
  null
)(ConnectedTerminal);

export default Terminal;

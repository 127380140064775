import * as React from 'react';
import { useState, useRef, useEffect } from 'react';

import PropTypes from 'prop-types';
import { alpha } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import EditIcon from "@material-ui/icons/Edit";
import LaunchIcon from '@material-ui/icons/Launch';
import Input from '@material-ui/core/Input';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
 
import { connect } from "react-redux";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import { getDevicesByProduct, getAllProducts } from '../utils/Cachengo';
import ErrorIcon from '@material-ui/icons/Error';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import TemporaryDrawer from './TemporaryDrawer';
import Grid from '@material-ui/core/Grid';
import CardHeader from '@material-ui/core/CardHeader';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AlbumIcon from '@material-ui/icons/Album';
import DeviceGeneralInfo from './DeviceGeneralInfo';
import { setDevicesToInstall, removeDevicesFromInstallation } from '../actions/installation';
import InstalledApps from './InstalledApps';
import AllInstalledApps from './AllInstalledApps';
import AllInstalledSwitches from './AllInstalledSwitches';
import AllInstalledKeys from './AllInstalledKeys';
import InstalledKeys from './InstalledKeys';
import InstalledSwitches from './InstalledSwitches';
import SetRentPriceModal from "./modals/SetRentPriceModal";
import SetRentModal from './modals/NewSetRentPriceModal';
import SetUninstallAppsModal from "./modals/SetUninstallAppsModal";
import MoveDevicesModal from "./modals/MoveDeviceModal";
import SetInstallKeysModal from './modals/InstallKeyModal';
import DeviceEditModal from './modals/DeviceEditModal';
import { getDeviceInfo, getInstallations, getAllInstallations, getOrgKeys } from '../utils/Cachengo';


import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import GetAppIcon from "@material-ui/icons/GetApp";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";

import {
  setConfirmationModalOpen,
  setSnackBarMessage,
} from "../actions/interactions";
import {
  removeMultipleDevices,
} from "../actions/installation";

import { removeDevice, getProducts, updateDevice } from "../utils/Cachengo";
import { removeEventHandler, addEventHandler } from '../actions/items';

import { getDevicesByProducts } from '../utils/Cachengo';
import CircularProgress from '@material-ui/core/CircularProgress';


const mapStateToProps = (state) => {
    return {
      payInfo: state.portal.payInfo || {},
      item: state.portal.itemToInstall,
      devices: state.portal.devicesToInstall,
    };
};

function mapDispatchToProps(dispatch) {
  return {
    removeEventHandler: (key) => dispatch(removeEventHandler(key)),
    addEventHandler: (key, handler) => dispatch(addEventHandler(key, handler)),
    setDevicesToInstall: (device) => dispatch(setDevicesToInstall(device)),
    removeDevicesFromInstallation: () => dispatch(removeDevicesFromInstallation()),
    openConfirmationModal: (item) => dispatch(setConfirmationModalOpen(item)),
    removeMultipleDevices: (handle) => dispatch(removeMultipleDevices(handle)),
    setSnackBarMessage: (message, type) => dispatch(setSnackBarMessage(message, type)),
    // setItemToInstall: (item) => dispatch(setItemToInstall(item)),
  }
}

const useStyles = makeStyles((theme) => ({
  bread:{
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    paper: {
      padding: theme.spacing(2),
      // textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    resources: {
      padding: theme.spacing(1),
      // textAlign: 'center',
      color: theme.palette.text.secondary,
      height: '100vh',
    },
    header: {
      textAlign: 'center',
      justifyContent: 'center',
      // padding: theme.spacing(2),
      width: '100%',
      textAlign: 'left',
      background: '#eaeded',
      color: 'black',
      fontWeight: 'bold',
      border: '1.5px solid black'
    },
    card: {
      border: '1px solid #d5dbdb',
      // maxHeight: '4vh'
      // borderRadius: '1px',
      // borderWeight: 'light',
    },
  }
}));

function createData(id, name, instance_id, instance_state, instance_type, private_v4, private_ipv6, product_id, product_name) {
  instance_type = instance_type !== null ? instance_type : "";
  private_v4 = private_v4 !== null ? private_v4 : "";
  private_ipv6 = private_ipv6 !== null ? private_ipv6 : "";

  return {
    id,
    name,
    instance_id,
    instance_state,
    instance_type,
    private_v4,
    private_ipv6,
    product_id,
    product_name,
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
  },
  {
    id: 'instance_id',
    numeric: true,
    disablePadding: false,
    label: 'Instance ID',
  },
  {
    id: 'instance_state',
    numeric: true,
    disablePadding: false,
    label: 'Instance state',
  },
  {
    id: 'instance_type',
    numeric: true,
    disablePadding: false,
    label: 'CPU Cores',
  },
  {
    id: 'private_v4',
    numeric: true,
    disablePadding: false,
    label: 'Private IPv4',
  },
  {
    id: 'private_ipv6',
    numeric: true,
    disablePadding: false,
    label: 'Private IPv6',
  },
  {
    id: 'product_id',
    numeric: true,
    disablePadding: false,
    label: 'Product ID'
  },
  {
    id: 'product_name',
    numeric: true,
    disablePadding: false,
    label: 'Product Name'
  },
];

function EnhancedTableHead(props) {

  const tableRef = useRef();

  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };


  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all instances',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
 
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const numSelected = props.numSelected;

  const handleDeleteDevice = (rowData) => {
    let handles = rowData.map((device) => device.handle);
    return removeDevice(handles).then(() => {
      props.removeMultipleDevices(handles);
      props.setSelected([]);
      props.setSelectedDevices([]);
    });
  };

  const handleUpdateDevice = (rowData) => {
    let handles = rowData.map((device) => device.handle);
    return updateDevice(handles).then(() => {
      props.removeMultipleDevices(handles);
      props.setSelected([]);
      props.setSelectedDevices([]);
    });
  };

  

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
      style={{justifyContent:'space-between'}}
    >
      {numSelected > 0 ? (
        <div>
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
          <Tooltip title="Delete Symbiote(s)">
        <IconButton onClick={() => {
          props.openConfirmationModal({
            show: true,
            message:
              "Are you sure you want to permanently delete the selected devices?",
            onYes: () => {
              handleDeleteDevice(props.selectedDevices).then(
                props.openConfirmationModal({
                  show: false,
                  message: null,
                  onYes: function () {},
                })
              );
            },
          })
        }}>
            <DeleteIcon />
        </IconButton>
        </Tooltip>
        <Tooltip title="Set Symbiote(s) for Rent">
        <IconButton
        onClick={()=>{
          props.toggleSetRentPriceModal(props.selectedDevices);
        }}
        >
            <AddShoppingCartIcon />
        </IconButton>
        </Tooltip>
        <Tooltip title="Change Organization">
        <IconButton
          onClick={() => {
            props.toggleMoveDevicesModal(props.selectedDevices);
          }}
        >
            <SwapHorizIcon />
        </IconButton>
        </Tooltip>
        <Tooltip title="Update Symbiote(s)">
        <IconButton onClick={()=>{
            props.openConfirmationModal({
            show: true,
            message: "Are you sure you want to update the selected devices?",
            onYes: () => {
              handleUpdateDevice(props.selectedDevices).then(
                props.openConfirmationModal({
                  show: false,
                  message: null,
                  onYes: function () {},
                })
              );
            },
          });
        }}>
            <GetAppIcon />
        </IconButton>
        </Tooltip>
        {numSelected === 1 ?
        <>
          <Tooltip title="Edit Symbiote Info">
            <IconButton
              onClick={() => {
                props.toggleEditInfoModal(props.selectedDevices);
              }}
            >
                <EditIcon />
            </IconButton>
            </Tooltip>
            <Tooltip title={"Open Shell"} placement="top">
            <IconButton
              onClick={() => props.openTerminal(props.selectedDevices[0].name, props.selectedDevices[0].handle)}
              aria-label={`refresh-data`}
            >
              <LaunchIcon />
            </IconButton>
          </Tooltip>
        </>
        :
        <></>

        }
        </Typography>
        </div>
      ) : (
        <div>
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Symbiotes
          <Tooltip title="Filter list">
        <IconButton>
            <FilterListIcon />
        </IconButton>
        </Tooltip>
        </Typography>

        </div>
      )}
      <div>
        <Input 
          type="text"
          value={props.value}
          onChange={props.onChange}
          placeholder="Search"
        />
      </div>
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function ConnectedEnhancedSymbioteTable(props) {
  const tableRef = useRef();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('product_id');
  const [orderSecondBy, setOrderSecondBy] = React.useState('name')
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(32);
  const [rows, setRows] = useState([]);
  const [installedApps, setInstalledApps] = useState([]);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [products, setProducts] = useState([])
  const [devices, setDevices] = useState([])
  const [isPriceModalOpen, setPriceModal] = useState(false);
  const [deviceHandles, setHandles] = useState([]);
  const [isMoveDevicesModalOpen, setMoveDevicesModal] = React.useState(false);
  const [deviceInfo, setDeviceInfo] = React.useState([]);
  const [allInstallations,setInstallations] = React.useState([]);
  const [deviceInstallations, setDeviceInstallations] = useState({});
  const [deviceConnections, setDeviceConnections] = useState({});
  const [deviceKeys, setDeviceKeys] = useState({});
  const [isKeysModalOpen, setKeysModal] = useState(false)
  const [keys, setKeys] = useState([])
  const [isEditModalOpen, setEditModal] = React.useState(false)
  const [editDevice, setEditDevice] = React.useState({'name':'','handle':''})
  const [loadState, setLoadState] = React.useState(null)
  const [infoLoadState, setInfoLoadState] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState("")

  const classes = useStyles();

  useEffect(() => {
    props.addEventHandler("deviceTable", (message) => {
      if (
        message.event === "new_device" ||
        message.event === "remove_device" ||
        message.event === "edit_device" ||
        message.event === "connect_switch" ||
        message.event === "disconnect_switch" ||
        message.event === "edit_device_info" ||
        message.event === "update_install_state" ||
        message.event === "device_offline" ||
        message.event === "device_online"
      ) {
        handleRefreshData();
        // clearSelectedItems();
      }
    });
    return () => props.removeEventHandler("deviceTable");
  }, [tableRef]);
  

  useEffect(() => {
    props.removeDevicesFromInstallation();
    handleRefreshData();
  }, [])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.filter((row) => searchFilter(row)).map((n) => n.id);
      var newSelectedDevs = []
      newSelected.forEach((id) => {
        newSelectedDevs.push(
          devices.filter(
            device => device.handle === visibleRows.filter(
              dev => dev.id === id
            )[0].instance_id
          )[0]
        )
      })

      let newDeviceInstallations = {}
      let newDeviceConnections = {}
      let newDeviceKeys = {}


      const devHandles = newSelectedDevs.map((dev)=>dev.handle)
      let devInstallations = []
      let devInfo = []

      setInfoLoadState(false)

      props.removeDevicesFromInstallation()
      props.setDevicesToInstall(newSelectedDevs)
      setSelectedDevices(newSelectedDevs)
      setSelected(newSelected);

      getDeviceInfo(devHandles).then(res => {
        setDeviceInfo(res.info)
        devInfo = res.info
      }).then( () =>
        {
          getAllInstallations(devHandles).then(res => {
            setInstallations(res.installations)
            devInstallations = res.installations
          }).then(() => {
            newSelectedDevs.forEach((device)=> {
              newDeviceInstallations[device.handle] = devInstallations.filter((app) => app.device === device.handle && app.state.toLowerCase() === "installed")
              newDeviceConnections[device.handle] = devInfo.filter((dev) => dev.handle === device.handle).map((info) => info.connections)
              newDeviceKeys[device.handle] = devInfo.filter((dev) => dev.handle === device.handle).map((info) => info.keys)
            })
            newDeviceInstallations = parseInstallations(newSelectedDevs.map((dev)=>dev.handle),newDeviceInstallations)
            newDeviceConnections = parseConnections(newSelectedDevs.map((dev)=>dev.handle),newDeviceConnections)
            newDeviceKeys = parseKeys(newSelectedDevs.map((dev)=>dev.handle),newDeviceKeys)
      
            setDeviceInstallations(newDeviceInstallations)
            setDeviceConnections(newDeviceConnections)
            setDeviceKeys(newDeviceKeys)
            setInfoLoadState(true)
            return;
          })
        }
      ) 
    } else {
      setSelected([]);
      setInfoLoadState(true)
    }
  };

  const goToMarketplace = () => {
    props.history.push('/marketplace')
  }

  const goToSwitch = () => {
    props.history.push('/peergroups')
  }

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    var newSelectedDevs = []
    newSelected.forEach((id) => {
      newSelectedDevs.push(
        devices.filter(
          device => device.handle === visibleRows.filter(
            dev => dev.id === id
          )[0].instance_id
        )[0]
      )
    })
    
    // let newDeviceApps = {}
    // let newDeviceConnections = {}
    // let newDeviceKeys = {}


    const devHandles = newSelectedDevs.map((dev)=>dev.handle)
    // let devInstallations = []
    // let devInfo = []

    setInfoLoadState(false)

    if (devHandles.length === 0){
      props.removeDevicesFromInstallation()
      props.setDevicesToInstall(newSelectedDevs)
      setSelectedDevices(newSelectedDevs)
      setSelected(newSelected); 
      setInstallations([])
      setDeviceConnections([])
      setDeviceKeys([])
      setDeviceInfo([])
      setInfoLoadState(true)
    } else {
      props.removeDevicesFromInstallation()
      props.setDevicesToInstall(newSelectedDevs)
      setSelectedDevices(newSelectedDevs)
      setSelected(newSelected);

      refreshInfo(devHandles,newSelectedDevs)
    }
  };

  const refreshInfo = (devHandles,newSelectedDevs) => {

    let newDeviceApps = {}
    let newDeviceConnections = {}
    let newDeviceKeys = {}
    let devInstallations = []
    let devInfo = []

    getDeviceInfo(devHandles).then(res => {
      setDeviceInfo(res.info)
      devInfo = res.info
    }).then( () =>
      {
        getAllInstallations(devHandles).then(res => {
          setInstallations(res.installations)
          devInstallations = res.installations
        }).then(() => {
          newSelectedDevs.forEach(device => {
            newDeviceApps[device.handle] = devInstallations.filter((app) => app.device === device.handle && app.state.toLowerCase() === "installed")
            newDeviceConnections[device.handle] = devInfo.filter((dev) => dev.handle === device.handle).map((info) => info.connections)
            newDeviceKeys[device.handle] = devInfo.filter((dev) => dev.handle === device.handle).map((info) => info.keys)
          })

          newDeviceApps = parseInstallations(newSelectedDevs.map((dev)=>dev.handle),newDeviceApps)
          newDeviceConnections = parseConnections(newSelectedDevs.map((dev)=>dev.handle),newDeviceConnections)
          newDeviceKeys = parseKeys(newSelectedDevs.map((dev)=>dev.handle),newDeviceKeys)
              
          setDeviceInstallations(newDeviceApps)
          setDeviceConnections(newDeviceConnections)
          setDeviceKeys(newDeviceKeys)
          setInfoLoadState(true)

        })
      }
    )
  } 

  const toggleSetRentPriceModal = (rowData) => {
    if (isPriceModalOpen) {
      setPriceModal(!isPriceModalOpen);
    } else {
      let devices = rowData.filter((device) => !device.is_vm);
      devices = devices.filter((device) => device.is_connected)

      let handles = devices.map((device) => device.handle);

      let numberNotEntered = rowData.length - devices.length;
      if (numberNotEntered === rowData.length) {
        let errMsg = "All devices are VMs or Offline and cannot be set for rent";
        props.setSnackBarMessage(errMsg, "error");
        return;
      }

      if (numberNotEntered > 0) {
        let msg1 = `${numberNotEntered} device was not set for rent because it is a VM or offline`;
        let msg2 = `${numberNotEntered} devices were not set for rent because they are VMs or offline`;
        let message = numberNotEntered > 1 ? msg2 : msg1;
        props.setSnackBarMessage(message, "warning");
      }
      setPriceModal(!isPriceModalOpen);
      setHandles(handles);
    }
  };

  const toggleEditInfoModal = (rowData) => {
    if (isEditModalOpen) {
      setEditModal(!isEditModalOpen)
    } else {
      setEditDevice({'name':rowData[0].name, 'handle':rowData[0].handle})
      setEditModal(!isEditModalOpen);
    }
  }

  const toggleSetKeyModal = (rowData) => {
    if (isKeysModalOpen) {
      setKeysModal(!isKeysModalOpen);
    } else {
      getOrgKeys().then(res => {
        if (res && res.success) { 
          setKeys(res.public_keys);
          // const devices = testData.filter(device => device.is_vm === true);
            let devices = selectedDevices
            devices = devices.filter((device) => device.is_connected)
      
            let handles = devices.map((device) => device.handle);
      
            let numberNotEntered = selectedDevices.length - devices.length;
            if (numberNotEntered === selectedDevices.length) {
              let errMsg = "All devices are offline";
              props.setSnackBarMessage(errMsg, "error");
              return;
            }
      
            if (numberNotEntered > 0) {
              let msg1 = `${numberNotEntered} device is not online`;
              let msg2 = `${numberNotEntered} devices were not used because they are offline`;
              let message = numberNotEntered > 1 ? msg2 : msg1;
              props.setSnackBarMessage(message, "warning");
            }
            setKeysModal(!isKeysModalOpen);
            setHandles(handles);
        } else {
          let errMsg = "Cannot retrieve keys";
          props.setSnackBarMessage(errMsg, "error");
          return;
        }
      })
    }
  };

  const toggleMoveDevicesModal = (rowData) => {
    let devices = rowData;
    if (isMoveDevicesModalOpen == false) {
      let handles = devices.map((device) => device.handle);
      setHandles(handles);
      setMoveDevicesModal(true);
    } else {
      setMoveDevicesModal(false);
    }
  };

  const handleRefreshData = ()=> {
    clearSelectedItems();
    getAllProducts({})
    .then(res => {
      setProducts(res.products)
      if (res.products.length > 0){
        const prod_handles = res.products.map((product) => product.handle)
        getDevicesByProducts(prod_handles).then((res) => {
          if (res && res.success)
            {
              let newDevices = {}
              let newRows = []
              let devHandles = []
              let newDevArr = []
              let devId = 0

              res.products.forEach((product) => {
                newDevices[product.handle] = product.devices;
                devHandles = [...devHandles, ...product.devices.map(dev => dev.handle)];
                newDevices[product.handle].forEach(function(device) {
                  newRows.push(createData(devId,device.name,device.handle,device.is_connected,device.cores,device.private_ip, device.private_ipv6, product.handle,product.name))
                  devId++
                })
                newDevArr.push(...product.devices);
              })

              setDevices(newDevArr)
              setRowsPerPage(newRows.length)
              setRows(newRows)
              setLoadState(true)
            }
        })
      } else {
        setRowsPerPage(0)
        setRows([])
        setDevices([])
        setLoadState(true)
      }
    })
    refreshData();
  }

  const refreshData = () => {
    tableRef.current && tableRef.current.refreshTable();
  };

  const clearSelectedItems = (handles) => {
    props.removeDevicesFromInstallation();
    setSelected([]);
    setSelectedDevices([]);
  };

  const history = props.history;

  const openTerminal = (name, handle) => {
    let idUrl = `/terminal/${name}/${handle}`
    history.push(idUrl);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const firstVisibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderSecondBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, rows],
  );

  const visibleRows = React.useMemo(
    () =>
      stableSort(firstVisibleRows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, rows],
  );

  const parseInstallations = (handles, installations) => {
    if (handles.length > 1) {
      let appsDict = {};

      for (let i = 0; i < handles.length; i++) {
        for (
          let n = 0;
          n < installations[handles[i]].length;
          n++
        ) {
          if (!appsDict[installations[handles[i]][n].name]) {
            appsDict[installations[handles[i]][n].name] = 1;
          } else {
            appsDict[installations[handles[i]][n].name]++;
          }
        }
      }
     
      if (Object.keys(appsDict)) {
        for (let key in appsDict) {
          if (appsDict.hasOwnProperty(key)) {
            if (appsDict[key] != handles.length) {
             delete appsDict[key];
              }
          }
        }
        for (let n = 0; n < Object.keys(installations).length; n++) {
         installations[Object.keys(installations)[n]] = installations[Object.keys(installations)[n]].filter((app) => Object.keys(appsDict).includes(app.name))
        }
        return installations;
      }
    }
    return installations;
  }

  const parseKeys = (handles, keys) => {
    if (handles.length > 1) {
      let appsDict = {};

      for (let i = 0; i < handles.length; i++) {
        for (
          let n = 0;
          n < keys[handles[i]][0].length;
          n++
        ) {
          if (!appsDict[keys[handles[i]][0][n].name]) {
            appsDict[keys[handles[i]][0][n].name] = 1;
          } else {
            appsDict[keys[handles[i]][0][n].name]++;
          }
        }
      }
     
      if (Object.keys(appsDict)) {
        for (let key in appsDict) {
          if (appsDict.hasOwnProperty(key)) {
            if (appsDict[key] != handles.length) {
             delete appsDict[key];
              }
          }
        }
        for (let n = 0; n < Object.keys(keys).length; n++) {
         keys[Object.keys(keys)[n]][0] = keys[Object.keys(keys)[n]][0].filter((app) => Object.keys(appsDict).includes(app.name))
        }
        return keys;
      }
    }
    return keys;
  }

  const parseConnections = (handles, connections) => {

    if (handles.length > 1) {
      let switchDict = {};

      for (let i = 0; i < handles.length; i++) {
        for (
          let n = 0;
          n < connections[handles[i]][0].length;
          n++
        ) {
          if (!switchDict[connections[handles[i]][0][n].switch]) {
            switchDict[connections[handles[i]][0][n].switch] = 1;
          } else {
            switchDict[connections[handles[i]][0][n].switch]++;
          }
        }
      }

      if (Object.keys(switchDict)) {
        for (let key in switchDict) {
          if (switchDict.hasOwnProperty(key)) {
            if (switchDict[key] != handles.length) {
             delete switchDict[key];
              }
          }
        }
        for (let n = 0; n < Object.keys(connections).length; n++) {
         connections[Object.keys(connections)[n]][0] = connections[Object.keys(connections)[n]][0].filter((connection) => Object.keys(switchDict).includes(connection.switch))
        }
        return connections;
      }
    }
    return connections;
  }

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase())
  }

  // Added search funcionality to table, filters results based on search term
  const searchFilter = (row) => {
    return searchTerm.toLowerCase() === '' 
    ? row
    : row.name.toLowerCase().includes(searchTerm) ||
      row.instance_id.toLowerCase().includes(searchTerm) ||
      row.private_v4.toLowerCase().includes(searchTerm) ||
      row.private_ipv6.toLowerCase().includes(searchTerm) ||
      row.product_id.toLowerCase().includes(searchTerm) ||
      row.product_name.toLowerCase().includes(searchTerm) ||
      isSelected(row.id)
  }

  return (
    // <Box sx={{ width: '100%' }}>
    <div style={{height:'100vh'}}>

      <Grid container spacing={3}>
      <Grid item xs={12}>
      <div className={classes.bread}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          <Link color="inherit" component="button" variant="body2" onClick={() => {props.history.push('/')}}>
            Home
          </Link>
          <Link color="inherit"  component="button" variant="body2" onClick={() => {props.history.push('/')}}>
            Knowhere
          </Link>
          <Typography color="textPrimary">Symbiotes</Typography>
        </Breadcrumbs>
      </div>
    <Paper sx={{ width: '100%', overflow: 'hidden', maxHeight: '35vh' }}>
        <EnhancedTableToolbar 
          numSelected={selected.length} history={history} selected={selected} 
          openConfirmationModal={props.openConfirmationModal} selectedDevices={selectedDevices} 
          setSelectedDevices={setSelectedDevices} setSelected={setSelected} removeMultipleDevices={props.removeMultipleDevices}
          toggleSetRentPriceModal={toggleSetRentPriceModal} toggleMoveDevicesModal={toggleMoveDevicesModal}
          toggleEditInfoModal={toggleEditInfoModal} openTerminal={openTerminal}
          onChange={handleSearchChange}
          value={searchTerm}
        />
        <TableContainer style={{ maxHeight: '35vh' }}>
          <Table
            stickyHeader
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.filter((row) => searchFilter(row)).length}
            />
            {loadState === true ?
            <TableBody>
              {visibleRows.filter((row) => searchFilter(row))
              .map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      {row.name}
                    </TableCell>
                    <TableCell align="right">{row.instance_id}</TableCell>
                    {
                        (row.instance_state === true)
                            ? <TableCell align="right" style={{color:'#1d8102'}}><CheckCircleOutlineIcon fontSize="small" style={{verticalAlign: 'bottom', color:'#1d8102'}}/>{"Running"}</TableCell>
                            : <TableCell align="right" style={{color:'red'}}><HighlightOffIcon fontSize="small" style={{verticalAlign: 'bottom', color:'red'}}/>{"Stopped"}</TableCell>
                    }
                    <TableCell align="right">{row.instance_type}</TableCell>
                    <TableCell align="right">{row.private_v4}</TableCell>
                    <TableCell align="right">{row.private_ipv6}</TableCell>
                    <TableCell align="right">{row.product_id}</TableCell>
                    <TableCell align="right">{row.product_name}</TableCell>


                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
              :
              <TableBody>
                <Box sx={{ display: 'flex' }}><CircularProgress/></Box>
              </TableBody>
              }
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={-1}
          component="div"
          count={rows.length}
          rowsPerPage={rows.length}
          // rowsPerPage={-1}
          page={page}
          // onChangePage={handleChangePage}
          // onChangeRowsPerPage={handleChangeRowsPerPage}
          // on
        />
      </Paper>
      </Grid>
      {/* <Divider style={{backgroundColor:'black'}}></Divider> */}
      <Grid item xs={12} className={classes.resources}>
          <Paper className={classes.resources} style={{height:'100vh'}}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
              { selected.length === 1 ?
                <CardHeader style={{borderBottom: '1px solid #d5dbdb'}} titleTypographyProps={{variant:'h6', fontWeight:'bold' }} xs={12} title={visibleRows.filter(device => device.id === selected[0])[0].name} className={classes.header}></CardHeader> :
                selected.length >= 1 ?
                <CardHeader titleTypographyProps={{variant:'h6', fontWeight:'bold' }} xs={12} title="Manage symbiotes" className={classes.header}></CardHeader> :
                <CardHeader titleTypographyProps={{variant:'h6', fontWeight:'bold' }} xs={12} title="Select a symbiote" className={classes.header}></CardHeader>
              }
              </Grid>
              { selected.length === 1 && infoLoadState ?
              <>
              <Grid item xs={12}>
                  <Accordion className={classes.heading} defaultExpanded>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading} fontWeight="fontWeightBold">
                        Instance Summary
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{background:'white'}}>
                      <DeviceGeneralInfo
                        name={devices.filter(device => device.handle === visibleRows.filter(dev => dev.id === selected[0])[0].instance_id)[0].name}
                        handle={devices.filter(device => device.handle === visibleRows.filter(dev => dev.id === selected[0])[0].instance_id)[0].handle}
                        last_seen={devices.filter(device => device.handle === visibleRows.filter(dev => dev.id === selected[0])[0].instance_id)[0].last_seen}
                        mac={devices.filter(device => device.handle === visibleRows.filter(dev => dev.id === selected[0])[0].instance_id)[0].mac}
                        private_ip={devices.filter(device => device.handle === visibleRows.filter(dev => dev.id === selected[0])[0].instance_id)[0].private_ip}
                        private_ipv6={devices.filter(device => device.handle === visibleRows.filter(dev => dev.id === selected[0])[0].instance_id)[0].private_ipv6}
                        status={devices.filter(device => device.handle === visibleRows.filter(dev => dev.id === selected[0])[0].instance_id)[0].status}
                        is_vm={devices.filter(device => device.handle === visibleRows.filter(dev => dev.id === selected[0])[0].instance_id)[0].is_vm}
                        ram={devices.filter(device => device.handle === visibleRows.filter(dev => dev.id === selected[0])[0].instance_id)[0].ram}
                        cores={devices.filter(device => device.handle === visibleRows.filter(dev => dev.id === selected[0])[0].instance_id)[0].cores}
                        sled_position={devices.filter(device => device.handle === visibleRows.filter(dev => dev.id === selected[0])[0].instance_id)[0].sled_position}
                        sled_number={devices.filter(device => device.handle === visibleRows.filter(dev => dev.id === selected[0])[0].instance_id)[0].sled_number}
                        product_type={devices.filter(device => device.handle === visibleRows.filter(dev => dev.id === selected[0])[0].instance_id)[0].product_type}
                        version={devices.filter(device => device.handle === visibleRows.filter(dev => dev.id === selected[0])[0].instance_id)[0].version}
                      />
                    </AccordionDetails>
                  </Accordion>
              </Grid>
              <Grid item xs={12}>
                  <Accordion className={classes.heading}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading} fontWeight="fontWeightBold">
                        Peer Groups
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{background:'white'}}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Button
                              style={{ height: "fit-content", backgroundColor:'#fc5001', fontWeight:'bold', color:'white'}}
                              variant="contained"
                              onClick={goToSwitch}
                          >
                              Join Peer Group
                          </Button>
                        </Grid>
                        <Grid item xs={12}>
                          <InstalledSwitches
                            // connections={deviceInfo.filter(device => device.handle === visibleRows.filter(dev => dev.id === selected[0])[0].instance_id)[0].connections || []}
                            connections={deviceConnections[selectedDevices.map((device) => device.handle)[0]][0] || []}
                            // handle={devices.filter(device => device.handle === visibleRows.filter(dev => dev.id === selected[0])[0].instance_id)[0].handle}
                            handle={selectedDevices.map((device) => device.handle)[0]}
                          />
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
              </Grid>
              <Grid item xs={12}>
                  <Accordion className={classes.heading}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading} fontWeight="fontWeightBold">
                        Applications
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{background:'white'}}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Button
                              style={{ height: "fit-content", backgroundColor:'#fc5001', fontWeight:'bold', color:'white'}}
                              variant="contained"
                              onClick={goToMarketplace}
                          >
                              Install Application
                          </Button>
                        </Grid>
                        <Grid item xs={12}>
                          <InstalledApps
                            apps={allInstallations.filter(app => selectedDevices.map((device)=> device.handle).includes(app.device)) || []}
                          />
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
              </Grid>
              <Grid item xs={12}>
                  <Accordion className={classes.heading}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading} fontWeight="fontWeightBold">
                        Public Keys
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{background:'white'}}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Button
                              style={{ height: "fit-content", backgroundColor:'#fc5001', fontWeight:'bold', color:'white'}}
                              variant="contained"
                              onClick={toggleSetKeyModal}
                          >
                              Install Public Key
                          </Button>
                        </Grid>
                        <Grid item xs={12}>
                          <InstalledKeys
                            keys={deviceKeys[selectedDevices.map((device) => device.handle)[0]][0] || []}
                            handle={selectedDevices.map((device) => device.handle)[0]}
                          />
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
              </Grid>
              </> : selected.length >= 1 && infoLoadState ? <>
              <Grid item xs={12}>
                  <Accordion className={classes.heading}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading} fontWeight="fontWeightBold">
                        Peer Groups
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{background:'white'}}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Button
                              style={{ height: "fit-content", backgroundColor:'#fc5001', fontWeight:'bold', color:'white'}}
                              // color="primary"
                              // color='white'
                              variant="contained"
                              // textColor="white"/
                              // theme={theme}
                              onClick={goToSwitch}
                          >
                              Join Peer Group
                          </Button>
                        </Grid>
                        <Grid item xs={12}>
                          <AllInstalledSwitches
                            connections={deviceConnections}
                            handles={selectedDevices.map((device) => device.handle)}
                            clearSelectedItems={clearSelectedItems}
                            handleOpenConfirmation={props.openConfirmationModal}
                          />
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
              </Grid>
              <Grid item xs={12}>
                  <Accordion className={classes.heading}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading} fontWeight="fontWeightBold">
                        Applications
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{background:'white'}}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Button
                              style={{ height: "fit-content", backgroundColor:'#fc5001', fontWeight:'bold', color:'white'}}
                              variant="contained"
                              onClick={goToMarketplace}
                          >
                              Install Application
                          </Button>
                        </Grid>
                        <Grid item xs={12}>
                          <AllInstalledApps
                            apps={deviceInstallations}
                            handles={selectedDevices.map((device) => device.handle)}
                            clearSelectedItems={clearSelectedItems}
                            handleOpenConfirmation={props.openConfirmationModal}
                          />
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
              </Grid>
              <Grid item xs={12}>
                  <Accordion className={classes.heading}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading} fontWeight="fontWeightBold">
                        Public Keys
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{background:'white'}}>
                      <Grid container spacing={3}>
                      <Grid item xs={12}>
                          <Button
                              style={{ height: "fit-content", backgroundColor:'#fc5001', fontWeight:'bold', color:'white'}}
                              variant="contained"
                              onClick={toggleSetKeyModal}
                          >
                              Install Public Key
                          </Button>
                        </Grid>
                        <Grid item xs={12}>
                          <AllInstalledKeys
                            keys={deviceKeys}
                            handles={selectedDevices.map((device) => device.handle)}
                            clearSelectedItems={clearSelectedItems}
                            handleOpenConfirmation={props.openConfirmationModal}
                          />
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
              </Grid>
              </>
              : selected.length > 0 && !infoLoadState ?
              <Box sx={{ display: 'flex' }}><CircularProgress/></Box>
              :
              <></>}
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
        </Grid>
      </Grid>
      {/* <TemporaryDrawer></TemporaryDrawer> */}
      <SetRentModal
         show={isPriceModalOpen}
         handleClose={toggleSetRentPriceModal}
         handles={deviceHandles}
         clearSelectedItems={clearSelectedItems}
       />
      <MoveDevicesModal
        show={isMoveDevicesModalOpen}
        handleClose={toggleMoveDevicesModal}
        handles={deviceHandles}
        refreshData={refreshData}
        handleOpenConfirmation={props.openConfirmationModal}
        clearSelectedItems={clearSelectedItems}
      />
      <SetInstallKeysModal
         show={isKeysModalOpen}
         handleClose={toggleSetKeyModal}
         handles={deviceHandles}
         keys={keys}
         clearSelectedItems={clearSelectedItems}
         handleOpenConfirmation={props.openConfirmationModal}
       />
      <DeviceEditModal
        name={editDevice.name}
        handle={editDevice.handle}
        show={isEditModalOpen}
        handleClose={toggleEditInfoModal}
        refreshTable={refreshData}
        clearSelectedItems={clearSelectedItems}
      />
    </div>

    // </Box>
  );
}

const EnhancedSymbioteTable = connect(
    mapStateToProps, mapDispatchToProps
  )(ConnectedEnhancedSymbioteTable);
  
  export default EnhancedSymbioteTable;

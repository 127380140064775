/*
 Copyright 2019, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

 http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */


 import { getJSON, postJSONwErrHandler, checkErrInRes, postJSON, getResponse, uploadFileReq } from './requests'
 import { CACHENGO_RENT_URL, CACHENGO_PAYMENTS_URL, CACHENGO_APPS_URL, CACHENGO_REPORTING_URL } from './Constants';
 
 
 export async function createAccount(username, email, password, errorHandler) {
   var data = {
     username: username,
     password: password,
     email: email
   };
   return postJSONwErrHandler('loggedout/new_user', data, errorHandler)
     .then(res => res)
     .catch(err => {
       console.log(err);
       return err
     })
 }
 
 export async function resetPassword(email, errorHandler) {
   var data = {
     email: email
   };
   return postJSONwErrHandler('loggedout/request_magic_login', data, errorHandler)
     .then(res => res)
     .catch(err => {
       console.log(err);
       return err
     })
 }
 
 export async function resendConfirmation() {
   return getJSON('/resend')
     .then(res => res)
     .catch(err => {
       return err
     })
 }
 
 export async function changePassword(password, errorHandler) {
   var data = {
     new_password: password,
   };
 
   return postJSONwErrHandler('change_password', data, errorHandler)
     .then(res => res)
     .catch(err => {
       console.log(err);
       return err
     })
 }
 
 export async function editUserInfo(name, email, public_key, errorHandler) {
   var data = {
     name: name,
     email: email,
     public_key: public_key,
   };
 
   return postJSONwErrHandler('edit_user_info', data, errorHandler)
     .then(res => res)
     .catch(err => {
       console.log(err);
       return err
     })
 }
 
 export async function createOrg(name, errorHandler){
   var data = {
     name: name
   };
   return postJSONwErrHandler('new_org', data , errorHandler)
     .then(res => res)
     .catch(err => {
       console.log(err); 
       return err
     })
 }
 
 export async function createApp(name, isPublic, url, price, category, description, parameters, organization, SSHInstall, errorHandler) {
   var data = {
     display_name: name,
     is_public: isPublic,
     app_url: url,
     price: price,
     category: category,
     description: description,
     parameters: parameters, 
     organization: organization,
     ssh_app: SSHInstall
   };
   return postJSONwErrHandler('new_app', data, errorHandler, CACHENGO_APPS_URL)
     .then(res => res)
     .catch(err => {
       console.log(err);
       return err
     })
 }
 
 export async function getReadme(appHandle, errorHandler) {
   let url = `get_readme/${appHandle}`
   return getResponse(url, CACHENGO_APPS_URL)
     .then(res => res)
     .catch(err => {
       errorHandler(err)
       console.log(err);
       return err
     })
 }
 
 export async function updateApp(data, errorHandler) {
   
   return postJSONwErrHandler('edit_app', data, errorHandler, CACHENGO_APPS_URL)
     .then(res => res)
     .catch(err => {
       console.log(err);
       return err
     })
 }
 
 export async function getAppInfo(app, errorHandler){
   var data = { handle: app };
   return postJSONwErrHandler('app_info', data, errorHandler, CACHENGO_APPS_URL)
     .then(res => res)
     .catch(err => {
       console.log(err);
       return err
     })
 }
 
 export async function removeApp(handles, errorHandler,){
   var data = { handles: handles };
   return postJSONwErrHandler('remove_app', data, errorHandler, CACHENGO_APPS_URL)
     .then(res => res)
     .catch(err => {
       console.log(err);
       return err
     })
 }
 
 export async function createSwitch(name, organization, errorHandler){
   var data = {
     name: name,
     organization: organization
   };
   return postJSONwErrHandler('new_switch', data, errorHandler)
     .then(res => res)
     .catch(err => {
       console.log(err);
       return err
     })
 }
 
 export async function createDevice(publicKey, organization, handle, name, errorHandler){
   var data = {
     public_key: publicKey,
     organization: organization,
     handle: handle,
     name: name
   };
   return postJSONwErrHandler('new_device', data, errorHandler)
     .then(res => res)
     .catch(err => {
       console.log(err);
       return err
     })
 }
 
 export async function removeDevice(handles, errorHandler){
   var data = {devices: handles};
   return postJSONwErrHandler('remove_device', data, errorHandler)
     .then(res => res)
     .catch(err => {
       console.log(err);
       return err
     })
 }
 
 export async function editDeviceInfo(data, errorHandler) {
   return postJSONwErrHandler('edit_device', data, errorHandler)
     .then(res => res)
     .catch(err => {
       console.log(err);
       return err
     })
 }
 
 export async function uninstallApp(handle, errorHandler){
   var data = {handle: handle}
   return postJSONwErrHandler('uninstall', data, errorHandler, CACHENGO_APPS_URL)
     .then(res => res)
     .catch(err => {
       console.log(err);
       return err
     })
 }
 
 export async function removeInstall(handle, errorHandler){
   var data = {handle: handle}
   return postJSONwErrHandler('remove_install', data, errorHandler, CACHENGO_APPS_URL)
     .then(res => res)
     .catch(err => {
       console.log(err);
       return err
     })
 }
 
 export async function removeSwitch(handles, errorHandler){
   var data = {
     handles: handles,
   }
   return postJSONwErrHandler('remove_switch', data, errorHandler)
     .then(res => res)
     .catch(err => {
       console.log(err);
       return err
     })
 }
 
 export async function disconnectSwitch(deviceId, switchId, errorHandler){
   var data = {
     switch_handle: switchId,
     device_handle: deviceId,
     disconnect: true
   }
   return postJSONwErrHandler('connect_switch', data, errorHandler)
     .then(res => res)
     .catch(err => {
       console.log(err);
       return err
     })
 }
 
 export async function uninstallKey(devices, key, errorHandler){
   var data = {
     public_key: key,
     devices: devices,
   }
   return postJSONwErrHandler('uninstall_key', data, errorHandler)
     .then(res => res)
     .catch(err => {
       console.log(err);
       return err
     })
 }
 
 export async function addUser(orgHandle, username, errorHandler){
   var data = {
     organization: orgHandle,
     username: username,
   }
   return postJSONwErrHandler('org/add_user', data, errorHandler)
     .then(res => res)
     .catch(err => {
       console.log(err);
       return err
     })
 }
 
 export async function removeUser(orgHandle, username, errorHandler){
   var data = {
     organization: orgHandle,
     username: username,
   }
   return postJSONwErrHandler('org/remove_user', data, errorHandler)
     .then(res => res)
     .catch(err => {
       console.log(err);
       return err
     })
 }
 
 export async function editUser(orgHandle, username, permissions, errorHandler){
   var data = {
     organization: orgHandle,
     username: username,
     permissions: permissions
   }
   return postJSONwErrHandler('org/edit_user', data, errorHandler)
     .then(res => res)
     .catch(err => {
       console.log(err);
       return err
     })
 }
 
 export async function removeOrg(orgHandle, errorHandler) {
   var data = {organization: orgHandle}
   return postJSONwErrHandler('org/remove_org', data, errorHandler)
     .then(res => res)
     .catch(err => {
       console.log(err);
       return err
     })
 }
 
 export async function getSecrets(installationHandle, errorHandler) {
   var data = {installation_handle: installationHandle}
   return postJSONwErrHandler('get_secrets', data, errorHandler, CACHENGO_APPS_URL)
     .then(res => res)
     .catch(err => {
       console.log(err);
       return err
     })
 }
 
 export async function deleteSecret(installationHandle, name, errorHandler) {
   var data = {
     installation_handle: installationHandle,
     name: name
   }
   return postJSONwErrHandler('delete_secret', data, errorHandler, CACHENGO_APPS_URL)
     .then(res => res)
     .catch(err => {
       console.log(err);
       return err
     })
 }
 
 export async function doRent(handles, names, errorHandler) {
   var data = {
     devices: handles,
     names: names
   }
   return postJSONwErrHandler('/rent', data, errorHandler, CACHENGO_RENT_URL)
     .then(res => res)
     .catch(err => {
       console.log(err);
       return err
     })
 }
 
 export async function setForRent(handles, for_rent, price, initial_fee, reservation_handle) {
   var data = {
     devices: handles, 
     for_rent: for_rent,
     price: price,
     initial_fee: initial_fee,
     reservation_handle: reservation_handle,
   }
   return postJSONwErrHandler('for_rent', data, null, CACHENGO_RENT_URL)
     .then(res => res)
     .catch(err => {
       console.log(err);
       return err
     })
 }
 
 export async function destroyVm(device_handle, errorHandler) {
   var data = {
     device_handle: device_handle
   }
   return postJSONwErrHandler('destroy_vm', data, errorHandler)
     .then(res => res)
     .catch(err => {
       console.log(err);
       return err
     })
 }
 
 export async function setupIntent(errorHandler) {
   return getJSON('setup_intent', CACHENGO_PAYMENTS_URL)
     .then(res => res)
     .catch(err => {
       errorHandler(err)
       console.log(err);
       return err
     })
 }
 
 export async function makeBuyer(payment_method, errorHandler) {
   var data = {payment_method}
   return postJSONwErrHandler('make_buyer', data, errorHandler, CACHENGO_PAYMENTS_URL)
     .then(res => res)
     .catch(err => {
       console.log(err);
       return err
     })
 }
 
 export async function fetchDevices(postData) {
   return postJSON('get_devices', postData)
     .then(checkErrInRes)
     .then(res => {
       return {...res, data:res.devices}
     })
     .catch(err => {
       throw err
     })
 }
 
 export async function fetchApps(postData) {
   return postJSON('app_list', postData, CACHENGO_APPS_URL)
     .then(checkErrInRes)
     .then(res => {
       return { ...res, data: res.apps }
     })
     .catch(err => {
       throw err
     })
 }
 
 export async function fetchAppCategories() {
   return getJSON('get_app_categories', CACHENGO_APPS_URL)
     .then(checkErrInRes)
     .then(res => {
       return { ...res, data: res.categories }
     })
     .catch(err => {
       throw err
     })
 }
 
 export async function getAppsByCategory(category, errorHandler) {
   var data = {
     category:category,
   }
   return postJSONwErrHandler('get_apps_by_category', data, errorHandler, CACHENGO_APPS_URL)
     .then(res => {
       return { ...res, data: res.apps }
     })
     .catch(err => {
       throw err
     })
 }
 
 export async function get_marketplace_apps(postData) {
   return postJSON('get_marketplace_apps', postData, CACHENGO_APPS_URL)
     .then(checkErrInRes)
     .then(res => {
       return { ...res, data: res.apps }
     })
     .catch(err => {
       throw err
     })
 }
 
 export async function fetchSwitches(postData) {
   return postJSON('get_switches', postData)
     .then(checkErrInRes)
     .then(res => {
       return { ...res, data: res.switches }
     })
     .catch(err => {
       throw err
     })
 }
 
 export async function fetchOrgUsers(postData) {
   return postJSON('org/get_users', postData)
     .then(checkErrInRes)
     .then(res => {
       let users = res.users.map(u => {
         return {
           name: u.name,
           email: u.email,
           is_admin: u.permission.is_admin,
           can_write: u.permission.can_write,
           can_read: u.permission.can_read,
           is_confirmed: u.permission.is_confirmed,
         }
       })
       return { ...res, data: users }
     })
     .catch(err => {
       throw err
     })
 }
 
 export async function fetchMyRentals(postData) {
   return postJSON('my_rentals', postData, CACHENGO_RENT_URL)
     .then(checkErrInRes)
     .then(res => {
       const handles = res.devices.map((device) => device.handle)
       return Promise.all([
         res,
         postJSONwErrHandler('device_info', { devices: handles, detail_view: false })
       ])
     })
     .then(responses => {
       let res1 = responses[0].devices
       let res2 = responses[1].info
 
       const rentals = res1.reduce((out, val) => Object.assign({ [val.handle]: val }, out), {})
       const mergedResponses = res2.map((val) => Object.assign(val, rentals[val.handle]))
 
       return { ...responses[0], data: mergedResponses }
 
     })
     .catch(err => {
       throw err
     }) 
 }
 
 export async function fetchAllMyRentals(postData) {
   return postJSON('all_rentals', postData, CACHENGO_RENT_URL)
     .then(checkErrInRes)
     .then(res => {
       const handles = res.devices.map((device) => device.handle)
       return Promise.all([
         res,
         postJSONwErrHandler('device_info', { devices: handles, detail_view: false })
       ])
     })
     .then(responses => {
       let res1 = responses[0].devices
       let res2 = responses[1].info
 
       const rentals = res1.reduce((out, val) => Object.assign({ [val.handle]: val }, out), {})
       const mergedResponses = res2.map((val) => Object.assign(val, rentals[val.handle]))
 
       return { ...responses[0], data: mergedResponses }
 
     })
     .catch(err => {
       throw err
     }) 
 }
 
 export async function fetchAvailableRentals(postData) {
   return postJSON('available', postData, CACHENGO_RENT_URL)
     .then(checkErrInRes)
     .then(res => {
       const handles = res.devices.map((device) => device.handle)
       return Promise.all([
         res,
         postJSONwErrHandler('device_info', { devices: handles, detail_view: false }, null)
       ])
     })
     .then(responses => {
       let res1 = responses[0].devices
       let res2 = responses[1].info
 
       const rentals = res1.reduce((out, val) => Object.assign({ [val.handle]: val }, out), {})
       const mergedResponses = res2.map((val) => Object.assign(val, rentals[val.handle]))
 
       return { ...responses[0], data: mergedResponses }
 
     })
     .catch(err => {
       throw err
     })
 }
 
 export async function fetchAvailableInstances(postData) {
   return postJSON('fetch_instance', postData, CACHENGO_RENT_URL)
     .then(checkErrInRes)
     .then(res => {
       const handles = res.devices.map((device) => device.handle)
       return Promise.all([
         res,
         postJSONwErrHandler('device_info', { devices: handles, detail_view: false }, null)
       ])
     })
     .then(responses => {
       let res1 = responses[0].devices
       let res2 = responses[1].info
 
       const rentals = res1.reduce((out, val) => Object.assign({ [val.handle]: val }, out), {})
       const mergedResponses = res2.map((val) => Object.assign(val, rentals[val.handle]))
 
       return { ...responses[0], data: mergedResponses }
 
     })
     .catch(err => {
       throw err
     })
 }
 
 export async function getMetrics(subject, start_time, end_time, metric_name, errorHandler) {
   var data = {
     subject: subject,
     start_time: start_time,
     end_time: end_time,
     metric_name: metric_name,
   }
   return postJSONwErrHandler('subject_metrics', data, errorHandler, CACHENGO_REPORTING_URL)
     .then(res => res)
     .catch(err => {
       console.log(err);
       return err
     })
 }
 
 export async function getDeviceInfo(handles, errorHandler) {
   const devices = typeof handles === 'string' ? [handles] : handles;
   var data = {
     devices: devices
   }
   return postJSONwErrHandler('device_info', data, errorHandler)
     .then(res => res)
     .catch(err => {
       console.log(err);
       return err
     })
 }
 
 export async function getClusterInfo(handles, errorHandler) {
  const clusters = typeof handles === 'string' ? [handles] : handles;
  var data = {
    clusters: clusters
  }
  return postJSONwErrHandler('cluster_info', data, errorHandler)
    .then(res => res)
    .catch(err => {
      console.log(err);
      return err
    })
}

 export async function getAllInstallations(handles, errorHandler) {
   const devices = typeof handles === 'string' ? [handles] : handles;
   var data = {
     devices: devices
   }
   return postJSONwErrHandler('get_all_installations', data, errorHandler, CACHENGO_APPS_URL)
     .then(res => res)
     .catch(err => {
       console.log(err);
       return err
     })
 }
 
 export async function getInstallations(device_handle, errorHandler) {
     var data = {
       device_handle: device_handle
     }
     return postJSONwErrHandler('get_installations', data, errorHandler, CACHENGO_APPS_URL)
       .then(res => res)
       .catch(err => {
         console.log(err);
         return err
       })
   }
 
 export async function getNotifications(errorHandler) {
   return postJSONwErrHandler('notifications', {}, errorHandler)
     .then(res => res)
     .catch(err => {
       console.log(err);
       return err
     })
 }
 
 export async function acceptOrgInvite(org, accept, errorHandler) {
   var data = {
     accept: accept,
     organization: org,
   }
   return postJSONwErrHandler('accept_invite', data, errorHandler)
     .then(res => res)
     .catch(err => {
       console.log(err);
       return err
     })
 }
 
 export async function delete_Notification(handle, errorHandler) {
   var data = {
     handle: handle,
   }
   return postJSONwErrHandler('delete_notification', data, errorHandler)
     .then(res => res)
     .catch(err => {
       console.log(err);
       return err
     })
 }
 
 export async function getOrgsSuperUser(page, per_page) {
   var data = {
     page,
     per_page
   }
   return postJSON('superuser/get_orgs', data)
     .then(res => res)
     .catch(err => {
       throw err
     })
 }
 
 export async function addKey(keyName, publicKey, errorHandler){
   var data = {
     name: keyName,
     public_key: publicKey,
   }
   return postJSONwErrHandler('org/new_key', data, errorHandler)
     .then(res => res)
     .catch(err => {
       console.log(err);
       return err
     })
 }
 
 export async function installKey(devices, publicKeys, errorHandler){
   var data = {
     devices: devices,
     public_keys: publicKeys,
   }
   return postJSONwErrHandler('install_key', data, errorHandler)
     .then(res => res)
     .catch(err => {
       console.log(err);
       return err
     })
 }
 
 export async function removeKey(keyHandles, errorHandler){
   var data = {
     public_keys: keyHandles
   }
   return postJSONwErrHandler('org/remove_key', data, errorHandler)
     .then(res => res)
     .catch(err => {
       console.log(err);
       return err
     })
 }
 
 export async function getOrgKeys() {
   var data = {}
   return postJSON('org/get_keys', data)
     .then(res => res)
     .catch(err => {
       throw err
     })
 }
 
 export async function getSwitchNodes(handle, page, per_page, errorHandler) {
   var data = {
     switch_handle: handle,
     page, 
     per_page,
   }
   return postJSONwErrHandler('node_by_peergroup', data, errorHandler)
     .then(res => {
       return { ...res, data: res.devices }
     })
     .catch(err => {
       console.log(err);
       return err
     })
 }
 
 export async function getSwitchInfo(handles, errorHandler) {
   var data = {
     switch_handles: [handles],
   }
   return postJSONwErrHandler('switch_info', data, errorHandler)
     .then(res => res)
     .catch(err => {
       console.log(err);
       return err
     })
 }
 
 export async function getFile(installType, id, errorHandler) {
     let url = `get_log/${installType}/${id}`
     return getResponse(url, CACHENGO_APPS_URL)
       .then(res => res)
       .catch(err => {
         errorHandler(err)
         console.log(err);
         return err
       })
   }
 
 export async function getUploadToken(app_handle, file_type, errorHandler) {
   var data = {
     handle: app_handle,
     file_type: file_type
   }
   return postJSONwErrHandler('get_app_upload_token', data, errorHandler, CACHENGO_APPS_URL)
 }
 
 export async function uploadFile(url, file, minioFields, errorHandler) {
   var data = {
     ...minioFields,
     file: file
   }
   return uploadFileReq(url, data, errorHandler)
 }
 
 // export async function getVms(postData) {
 //   return postJSON('get_devices_by_product', postData)
 //   .then(checkErrInRes)
 //   .then(res => res)
 //   .catch(err => {
 //     throw err
 //   })
 // }
 
 export async function getDevicesByProduct(handle, errorHandler) {
   var data = {
     handle: handle,
   }
   return postJSONwErrHandler('get_devices_by_product', data, errorHandler)
     .then(res => res)
     .catch(err => {
       console.log(err);
       return err
     })
 }
 
 export async function getDevicesByProducts(handles, errorHandler) {
   var data = {
     handles: handles,
   }
   return postJSONwErrHandler('get_devices_by_products', data, errorHandler)
     .then(res => res)
     .catch(err => {
       console.log(err);
       return err
     })
 }
 
 export async function getProducts(postData) {
   return postJSON('get_products', postData)
     .then(checkErrInRes)
     .then(res => {
         let last_page = Math.ceil(res.total / postData.per_page)
         last_page = Math.max(last_page, 1)
         if (last_page === postData.page) {
             res.products.push({
                 name: 'Single Devices',
                 handle: null,
                 product_type: ''
             })
         }
       return {...res, data: res.products}
     })
     .catch(err => {
       throw err
     })
 }
 
 export async function getAllProducts(postData) {
   return postJSON('get_all_products', postData)
     .then(checkErrInRes)
     .then(res => {
       return {...res, data: res.products}
     })
     .catch(err => {
       throw err
     })
 }
 
 export async function editProduct(data, errorHandler) {
   return postJSONwErrHandler('edit_org_product', data, errorHandler)
     .then(res => res)
     .catch(err => {
       console.log(err);
       return err
     })
 }
 
 
 export async function get_devices_manufacturer(postData) {
   return postJSON('get_devices_manufacturer', postData)
     .then(checkErrInRes)
     .then(res => {
       return { ...res, data: res.devices }
     })
     .catch(err => {
       throw err
     })
 }
 
 export async function edit_device_manufacturer(postData, errorHandler) {
   return postJSONwErrHandler('edit_device_manufacturer', postData, errorHandler)
     .then(res => {
       return res 
     })
     .catch(err => {
       return err
     })
 }
 
 export async function edit_sled_manufacturer(postData, errorHandler) {
   return postJSONwErrHandler('assign_sled_product', postData, errorHandler)
     .then(res => {
       return res 
     })
     .catch(err => {
       return err
     })
 }
 
 export async function startShell(device, height, width, errorHandler) {
   var data = {
     device: device,
     height: height,
     width: width
   }
   return postJSONwErrHandler('start_shell', data, errorHandler)
     .then(res => res)
     .catch(err => {
       console.log(err);
       return err
     })
 }
   
 export async function shellCommand(target, payload, errorHandler) {
   var data = {
     target: target,
     payload: payload
   }
   return postJSONwErrHandler('command_shell', data, errorHandler)
     .then(res => res)
     .catch(err => {
       console.log(err);
       return err
     })
 }
 
 export async function updateDevice(handles, errorHandler){
     var data = {devices: handles};
     return postJSONwErrHandler('update_device', data, errorHandler)
       .then(res => res)
       .catch(err => {
         console.log(err);
         return err
       })
   }
 
 export async function moveDevice(handles, org, errorHandler){
   var data = {devices: handles, organization: org};
   return postJSONwErrHandler('move_device', data, errorHandler)
     .then(res => res)
     .catch(err => {
       console.log(err);
       return err
     })
 }
 
 export async function createCluster(name, secret, errorHandler){
   var data = {
     name: name,
     secret: secret,
   };
   return postJSONwErrHandler('new_cluster', data, errorHandler)
     .then(res => res)
     .catch(err => {
       console.log(err);
       return err
     })
 }
 
 // export async function fetchClusters(postData) {
 //   return postJSON('get_clusters', postData)
 //     .then(checkErrInRes)
 //     .then(res => {
 //       return { ...res, data: res.clusters }
 //     })
 //     .catch(err => {
 //       throw err
 //     })
 
 // }
 
 export async function fetchClusters(errorHandler) {
   var data = {}
   return postJSONwErrHandler('get_clusters', data, errorHandler)
     .then(res => res)
     .catch(err => {
       console.log(err);
       return err
     })
 }
 
 export async function removeCluster(handles, errorHandler){
   var data = {
     handles: handles,
   };
   return postJSONwErrHandler('remove_cluster', data, errorHandler)
     .then(res => res)
     .catch(err => {
       console.log(err);
       return err
     })
 }
 
 export async function disconnectCluster(deviceIds, clusterId, isServer, errorHandler){
   var data = {
     cluster_handle: clusterId,
     devices: deviceIds,
     is_server: isServer,
     disconnect: true
   }
   return postJSONwErrHandler('connect_cluster', data, errorHandler)
     .then(res => res)
     .catch(err => {
       console.log(err);
       return err
     })
 }
 
 export async function connectCluster(deviceIds, clusterId, serverNum, agentNum, errorHandler){
   var data = {
     cluster_handle: clusterId,
     devices: deviceIds,
     servers: serverNum,
     agents: agentNum,
     disconnect: false
   }
   return postJSONwErrHandler('connect_cluster', data, errorHandler)
     .then(res => res)
     .catch(err => {
       console.log(err);
       return err
     })
 }
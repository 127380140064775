/*
 Copyright 2019, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at
 http://www.apache.org/licenses/LICENSE-2.0
 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */
import React, { useRef, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { getAppCategories } from '../actions/apps';
import { get_marketplace_apps } from '../utils/Cachengo';
import { CACHENGO_APPS_URL } from '../utils/Constants';
import { setConfirmationModalOpen, setSnackBarMessage } from '../actions/interactions';
import { connect } from 'react-redux';
import { setItemToInstall, removeItemFromInstallation } from '../actions/installation';
import CustomTable from './CustomTable';
import CustomTableInstallSummary from './CustomTableInstallSummary';
import AppIcon from './AppIcon';
import MarketApplication from './MarketApplication';

const mapStateToProps = state => {
  return {
    show: state.portal.isConfirmationModalOpen.show,
    item: state.portal.itemToInstall,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    setSnackBarMessage: (message, type) => dispatch(setSnackBarMessage(message, type)),
    setItemToInstall: (item) => dispatch(setItemToInstall(Object.assign({}, item, { type: 'application' }))),
    removeItemFromInstallation: () => dispatch(removeItemFromInstallation()),
    openConfirmationModal: (item) => dispatch(setConfirmationModalOpen(item)),
  };
}

const ConnectedMarketPlaceTable = props => {
  const tableRef = useRef();

  let selectedItems = [];
  if (props.item.type && props.item.type === 'application') {
    selectedItems = ([props.item])
  }


  const setSelectedItems = items => {
    if (items.length === 0) {
      props.removeItemFromInstallation()
      return;
    }

    props.openConfirmationModal(
      {
        show: true,
        message: `Installing ${items[0].name} has a cost of $${items[0].price !== undefined ? items[0].price : 0} per month per 
                  installed device and will be counted on a per day basis. Your account will 
                  be billed automatically at the end of the month. Are you sure you want to continue?`,
        onYes: () => {
          props.setItemToInstall(items[0])
          props.setSnackBarMessage('App added to installation', 'success')
          props.openConfirmationModal({ show: false, message: null, onYes: function () { } })
        }
      }
    );
  }

  const addNewApp = () => {
    props.history.push({
      pathname: "/new_app",
      isPublic: true
    })
  }

  const appIcon = rowData => {
    return (
      <AppIcon
          alt={rowData.name}
          appHandle ={rowData.handle}
          style={{ width: '1.5em', height: '1.5em', marginRight:'8px' }}>
      </AppIcon>   
    )
  };
  const columnHead = {
    field: "name", renderIcon: appIcon
  }
  const columns = [
    { title: "Description", field: "description" },
    {
      title: "Price", 
      field: "price", 
      render: rowdata => (
        <Typography variant='body2'> Price: ${rowdata.price}</Typography>
      )
    },
    { title: "Owner", field: "org" },
  ]

  const goToReadme = (e, rowData) => {
    const handle = rowData.handle;
    let idUrl = '/get_readme/' + handle;
    props.history.push({
      pathname: idUrl,
      name: rowData.name
    });

  }

  const moreInfo = {
    tooltip: 'App Info',
    handleMoreInfo: goToReadme
  }

  const SecondaryHeader = (
    <CustomTableInstallSummary />
  )

  return (
    <Grid style={{ paddingTop: '20px' }}>
      <CustomTable
        ref={tableRef}
        SecondaryHeader={SecondaryHeader}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        title='Marketplace'
        getData={get_marketplace_apps}
        createNewItemAction={{ tooltip: 'Create New App', handleCreateNew: addNewApp }}
        columns={columns}
        columnHead={columnHead}
        moreInfo={moreInfo}
        errMessage={'Problem fetching Apps'}
        showInstalls={true}
        itemOverride={MarketApplication}
      />
    </Grid>
  );
}

const MarketPlaceTable = connect(mapStateToProps, mapDispatchToProps)(ConnectedMarketPlaceTable);

export default MarketPlaceTable;

/*
 Copyright 2019, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

 http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */

 import React, { useEffect, useState } from "react";
 import CardActions from '@material-ui/core/CardActions';
 import CardContent from '@material-ui/core/CardContent';
 import Button from '@material-ui/core/Button';
 import Typography from '@material-ui/core/Typography';
 import Grid from '@material-ui/core/Grid';
 import Box from '@material-ui/core/Box';
 import { makeStyles } from "@material-ui/core/styles";
 import ListItemAvatar from '@material-ui/core/ListItemAvatar';
 import ListItemText from '@material-ui/core/ListItemText';
 import Avatar from '@material-ui/core/Avatar';
 import RouterIcon from '@material-ui/icons/Router';
 import LinearProgress from '@material-ui/core/LinearProgress';
 
 
 import { connect } from 'react-redux';
 import { installScriptApp } from '../actions/installation';
 import { getAppInfo, getDeviceInfo } from '../utils/Cachengo';
 import {getMagicFillOptions} from '../utils/Helpers'
 import { removeItemFromInstallation, removeSingleDevice} from '../actions/installation';
 import InstallationItemCard from "./InstallationItemCard";
 import InstallationDeviceCard from "./InstallationDeviceCard";
 
 const useStyles = makeStyles(theme => ({
   root: {
     paddingTop: '20px',
     flexDirection: 'column',
     [theme.breakpoints.up("lg")]: {
       padding: theme.spacing(2.5,4)
     }
   }
 }))
 const mapStateToProps = state => {
   return {
     item: state.portal.itemToInstall,
     devices: state.portal.devicesToInstall
   }
 }
 
 function mapDispatchToProps(dispatch) {
   return {
     removeItemFromInstallation: () => dispatch(removeItemFromInstallation()),
     removeSingleDevice: (handle) => dispatch(removeSingleDevice(handle)),
     installScriptApp: (payload) => dispatch(installScriptApp(payload))
   };
 }
 
 const ConnectedInstallationCard = (props)=> {
   const classes = useStyles();
   const [parameters, setParameters] = useState([]);
   const [deviceInfoList, setDeviceInfoList] = useState([]);
   const [installName, setInstallName] = useState('');
   const [installerNodeKey, setInstallerNode] = useState(0);
   const [isLoading, setIsLoading] = useState(false);
   const [magicFillOptions, setMagicFillOptions] = useState([]);
 
   useEffect(() => {
     if (props.item.type === 'application') {
       getAppInfo(props.item.handle)
       .then(res=> {
         setParameters(res.info.parameters)
       })
     }
   }, [props.item]);
 
   useEffect(() => {
     const handles = props.devices.map(dev => dev.handle )
     getDeviceInfo(handles).then(res =>{
       if (res) {
         setDeviceInfoList(res.info)
         setMagicFillOptions( getMagicFillOptions(res.info) )
       }
     })
   }, []);
 
   const handleInstallApp = () => {
     const parsedParams = parameters.map((param, i)=> {
       let parsedValue = param.value
       if (param.value === undefined) {
         return null;
       }
       if (param.parameter_type === 'integer') {
         parsedValue = parseInt(param.value);
       }
       return { handle: param.handle, value: parsedValue };
     })
     const devices = props.devices;
     const deviceHandleList = [...new Set(devices.map(device => device.handle))];
     const payload = {
       name: installName,
       devices: deviceHandleList,
       app_handle: props.item.handle,
       parameters: parsedParams.filter((obj) => (obj != null)),
       installer_node: devices[installerNodeKey].handle
     }
     setIsLoading(true)
     props.installScriptApp(payload)
       .then(res => {
         setIsLoading(false)
         goBack()
       })
   }
 
   const goBack = () => {
     // props.history.goBack()
     props.history.push('/')
   }
   
   const remDevFromDeviceInfoList = (handle)=>{
     const newList = deviceInfoList.filter(dev => dev.handle != handle )
     setDeviceInfoList(newList)
     setMagicFillOptions(getMagicFillOptions(newList))
   }
 
   const copyMagicfill = (paramIndex, values) => {
     var params = parameters;
     
     // remove null, and undefined values
     let filtered = values.filter(obj => (!!obj))
     if (parameters[paramIndex].parameter_type === 'string'){
       filtered = filtered.join(",")
     }
 
     params[paramIndex].value = filtered;
     setParameters([...params]);
   }
 
   return (
     <div className={classes.root}>
       <Box>
         <CardContent>
           <Typography align="left" variant="h5" component="h2" style={{paddingLeft: '8px'}}>
             Installation Configuration
           </Typography>
           <Grid item>
             <div>
               <InstallationItemCard
                 installName={installName}
                 setInstallName={setInstallName}
                 removeItemFromInstallation={props.removeItemFromInstallation}
                 parameters={parameters}
                 setParameters={setParameters}
                 item={props.item}
                 magicFillOptions={magicFillOptions}
                 copyMagicfill={copyMagicfill}
               />
               <div style={{ paddingTop: '16px' }}>
                 <Typography style={{ padding: '16px 8px 0' }} variant="h6" gutterBottom>
                   Devices
                 </Typography>
                 {deviceInfoList.length < 1 && <NoDevice />}
                 {deviceInfoList.map((device, index) => (
                   <InstallationDeviceCard key={device.handle}
                     deviceInfo={device}
                     item={props.item}
                     index={index}
                     installerNodeKey={installerNodeKey}
                     setInstallerNode={setInstallerNode}
                     removeSingleDevice={props.removeSingleDevice}
                     remDevFromDeviceInfoList={remDevFromDeviceInfoList}
                   />
                 ))}
               </div>
             </div>
           </Grid>
         </CardContent>
         {isLoading && <LinearProgress color="secondary" />}
         <CardActions style={{ display:'flex', justifyContent:'center' }}>
           <br />
           <hr />
           <Button
             variant="outlined"
             size="small"
             onClick={goBack}
           >
             Cancel
           </Button>
           <Button 
             color="secondary"
             variant="contained"
             size="small" 
             onClick={handleInstallApp}
             disabled={props.devices.length < 1 || !props.item.type || isLoading}
           >
             Install {props.item.name ? props.item.name : ''}
           </Button>
         </CardActions>
       </Box>
     </div>
   );
 }
 
 
 const InstallationCard = connect(mapStateToProps, mapDispatchToProps)(ConnectedInstallationCard);
 
 export default InstallationCard;
 
 
 const NoDevice = () => {
   return (
     <div style={{ padding: '0px 32px' }}>
       <Grid item style={{ display: 'flex', alignItems: 'center'}}>
         <ListItemAvatar>
           <Avatar >
             <RouterIcon />
           </Avatar>
         </ListItemAvatar>
         <ListItemText
           primary={'No Device Selected'}
           secondary={'Please click on devices to select a device'}
         />
       </Grid>
     </div>
   )
 }

/*
 Copyright 2019, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at
 
 http://www.apache.org/licenses/LICENSE-2.0
 
 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */

export const runOnEnter = toRun => event => {
  if (event.key === 'Enter') {
    toRun();
  }
}
export const isEmpty = value =>
  value === undefined ||
  value === null ||
  (typeof value === 'object' && Object.keys(value).length === 0) ||
  (typeof value === 'string' && value.trim().length === 0); 

export const capitalizeWord = text => {
  const capitalize = text[0].toUpperCase() + text.substring(1)
  return capitalize
}

export const capitalizeAllWords = text => {
  return text.replace(/(^\w{1})|(\_\w{1})/g, letter => letter.toUpperCase()).replace('_', " ")
}

export const isInSelectedItem = (item, selectedItem, filterProperty) => {
  if (Array.isArray(selectedItem)) {
    const isIn = selectedItem.filter(obj => obj[filterProperty] === item[filterProperty]);
    if (isIn.length < 1) {
      return false
    }
    return true
  }

  if (selectedItem && item[filterProperty] === selectedItem[filterProperty]) {
    return true;
  }
  return false;
}

export const fetchDataTable = (query, fetchFunc, orgHandle, refreshTable, errHandler) => {
  const data = {
    organization: orgHandle,
    per_page: query.pageSize,
    page: query.page + 1
  }
  return new Promise((resolve, reject) => {
    fetchFunc(data,)
    .then(res => { 
      //handles error if a user enters a page that is out of range
      if (res.total / query.pageSize < res.page - 1) {
        resolve({
          data: res.data,
          page: 0,
          totalCount: 0
        })
        refreshTable()
      }
      //if you delete the last item on a page (in a table), it causes an error,
      if (res.data.length === 0 && res.total > 0) {
        resolve({
          data: res.data,
          page: res.page - 2,
          totalCount: 0
        })
        refreshTable()

      } else {
        resolve({
          data: res.data,
          page: res.page - 1,
          totalCount: res.total
        })
      }
    })
    .catch(err => {
      errHandler()
      resolve({ data: [], page: 0, totalCount: 0 })
    })
  })
}

export function UUIDToHex(uuid)
{
  var digest ='';
  const ar  = new Uint8Array(str2ab(uuid));
  for (var x=0;x<ar.length;x++)
  {
    digest += ar[x].toString();
  }
  return digest;
}

function str2ab(str) {
  var buf = new ArrayBuffer(str.length*2); // 2 bytes for each char
	var bufView = new Uint8Array(buf);
	for (var i=0, strLen=str.length; i < strLen; i++) {
	  bufView[i] = str.charCodeAt(i);
	}
	return buf;
}

export const getMagicFillOptions = (deviceInfoList) => {
  const tempPeergroups = {}
  const ips = []
  const vips = []
  const hostnames = []

  deviceInfoList.forEach(device => {
    ips.push(device.private_ip)
    hostnames.push(device.name)
    vips.push(device.private_ipv6)
    if (device.connections) {
      device.connections.forEach(peergroup => {
        if(tempPeergroups[peergroup.switch_handle]){
          tempPeergroups[peergroup.switch_handle].values.push(peergroup.ip)
        }else{
          tempPeergroups[peergroup.switch_handle] = {
            values:[peergroup.ip],
            text: `All IPs of peergroup ${peergroup.switch}`,
            id: peergroup.switch_handle
          }
        }
      })}
  })

	const peergroups = Object.values(tempPeergroups)
  const ipObj = {
    text: 'All private IPs of devices',
    values: ips,
    id: 'AllPrivateIPsOfDevices'
  }
  const ipv6Obj = {
    text: 'All private IPv6s of devices',
    values: vips,
    id: 'AllPrivateIPv6sOfDevices'
  }
  const handleObj = {
    text: 'All hostnames of devices',
    values: hostnames,
    id: 'AllHostnamesOfDevices'
  }

  return [ipObj, ...peergroups, handleObj, ipv6Obj]

}

export const stripOIDC = (value) =>
   value && value.startsWith('oidc:') ? value.slice('oidc:'.length) : value;

/*
 Copyright 2019, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at
 http://www.apache.org/licenses/LICENSE-2.0
 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */

 import React, { Component, useEffect } from "react";
 import Button from "@material-ui/core/Button";
 import Grid from "@material-ui/core/Grid";
 import Dialog from "@material-ui/core/Dialog";
 import DialogActions from "@material-ui/core/DialogActions";
 import DialogTitle from "@material-ui/core/DialogTitle";
 import Typography from "@material-ui/core/Typography";
 import DialogContent from "@material-ui/core/DialogContent";
 
 import OrganizationList from "../OrganizationList";
 import { moveDevice } from "../../utils/Cachengo";
 import { connect } from "react-redux";

 
 const mapStateToProps = (state) => {
   return {
     org: state.portal.selectedOrg,
   };
 };
 
 class ConnectedMoveDevicesModal extends Component {
   constructor(props) {
     super(props);
 
     this.onChange = this.onChange.bind(this);
     this.handleMoveDevices = this.handleMoveDevices.bind(this);
     this.handleListItem = this.handleListItem.bind(this);
     this.changeOrg = this.changeOrg.bind(this);
     this.state = {organization: props.org};
   }
 
   changeOrg(organization) {
     this.setState({ organization: organization });
   }
 
   onChange = (field) => (event) => {
     var state = {};
     state[field] = event.target.value;
     this.setState(state);
   };
 
   handleListItem = (event) => {
     this.setState({ organization: event });
   };
 
  handleMoveDevices = () => {
    // let handles = this.props.handles.map((device) => device.handle);
    let handles = this.props.handles
    return moveDevice(handles, this.state.organization).then(() => {
      // this.props.removeMultipleDevices(handles);
      this.props.clearSelectedItems(handles);
    });
  };

  handleOpenConfirmationModal = (apps, app) => {
    this.props.handleOpenConfirmation({
      show: true,
      message: "Are you sure you want to switch organizations?",
      onYes: () => {
      //  this.saveOrgChoice();
      this.handleMoveDevices().then(() => {
        this.props.handleClose()
        this.props.handleOpenConfirmation({
          show: false,
          message: null,
          onYes: function () {},
        });
      })
      },
    });
  };
 
   render() {
     return (
       <Dialog
         onBackdropClick="false"
         open={this.props.show}
         onClose={this.props.handleClose}
         aria-labelledby={this.props.name}
         fullWidth
       >
         <DialogTitle id={this.props.name}>
           <Grid justify="space-between" container spacing={2}>
             <Grid item>
               <Typography variant="h6" align="center">
                 Choose a new location for your devices
               </Typography>
             </Grid>
           </Grid>
         </DialogTitle>
         <DialogContent>
           <OrganizationList
             onChange={this.handleListItem}
             value={this.state.organization}
           />
         </DialogContent>
         <DialogActions>
           <Button 
              disabled={this.state.organization.handle == this.props.org.handle || this.state.organization == this.props.org.handle} 
              onClick={this.handleOpenConfirmationModal}>Confirm</Button>
           <Button onClick={this.props.handleClose}>Cancel</Button>
         </DialogActions>
       </Dialog>
     );
   }
 }
 
 const MoveDeviceModal = connect(
   mapStateToProps,
   null
 )(ConnectedMoveDevicesModal);
 
 export default MoveDeviceModal;

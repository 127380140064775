import React from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import DialogContent from '@material-ui/core/DialogContent';
import Tooltip from "@material-ui/core/Tooltip";
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import AddBoxIcon from '@material-ui/icons/AddBox';
import Typography from '@material-ui/core/Typography';
import ImportantDevicesIcon from '@material-ui/icons/ImportantDevices';

import { runOnEnter } from '../../utils/Helpers';
import { createOrg, acceptOrgInvite } from '../../utils/Cachengo';
import { displayRequestError, setManufacturerPortal } from '../../actions/interactions';
import { setOrganization, updateOrgList } from '../../actions/organization';
import { logout } from '../../actions/user';
import { getOrgsSuperUser } from '../../utils/Cachengo';
import { setSnackBarMessage } from '../../actions/interactions';
import userManager from '../../utils/userManager';

const mapStateToProps = state => {
  return state.oidc ? {
    selectedOrg: state.portal.selectedOrg,
    organizations: state.portal.organizations,
    currentUser: state.portal.currentUser,
    manufacturerPortal: state.portal.manufacturerPortal,
    oidcUser: state.oidc.user,
  } :
  {
    selectedOrg: state.portal.selectedOrg,
    organizations: state.portal.organizations,
    currentUser: state.portal.currentUser,
    manufacturerPortal: state.portal.manufacturerPortal,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    displayRequestError: (res) => dispatch(displayRequestError(res)),
    updateOrgList: () => dispatch(updateOrgList()),
    setOrg: (payload) => dispatch(setOrganization(payload)),
    logout: () => dispatch(logout()),
    setSnackBarMessage: (message, type) => dispatch(setSnackBarMessage(message, type)),
    openManufacturerPortal: () => dispatch(setManufacturerPortal(true)),
  };
}

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
  },
  dialogActions: {
    justifyContent: 'space-between'
  },
  unconfirmedRoot: {
    padding: theme.spacing(2)
  },
  text2: {
    
  },
  btnDiv: {
    padding: theme.spacing(1.5, 0, 1.5),
    display: 'flex',
  },
}));

const ConnectedSelectOrgModal = (props) => {

  const [isNewOrgOpen, setNewOrgOpen] = React.useState(false);
  const [newOrgName, setOrgName] = React.useState('');

  const [superOrgs, setSuperOrgList] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [totalOrgs, setTotalOrgs] = React.useState(0);
  const [superUserSelected, setSuperUser] = React.useState(false);
  const history = useHistory()
  const location = useLocation()

  React.useEffect(() => {
    if (location.pathname.includes('/manufacturer_portal')){
      props.openManufacturerPortal()
    }

    if (superUserSelected) {
      handleGetOrgsSuperUser(1, rowsPerPage)
    } else {
      props.updateOrgList()
    }
  }, [superUserSelected]);

  const toggleSuperUser = () => {
    setSuperUser(!superUserSelected)
  }
  const handleGetOrgsSuperUser = (page, per_page) => {
    getOrgsSuperUser(page, per_page)
      .then(res => {
        setSuperOrgList(res.organizations)
        setTotalOrgs(res.total)
      }).catch(err => {
        props.setSnackBarMessage('Problem fetching orgs for Super User', 'error')
        setSuperOrgList([])
      })
  }
  const handleChangePage = (event, newPage) => {
    handleGetOrgsSuperUser(newPage + 1, rowsPerPage)
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRows = parseInt(event.target.value, 10)
    setRowsPerPage(newRows);
    handleGetOrgsSuperUser(1, newRows)
    setPage(0);
  };

  const toggleNewOrg = () => {
    setNewOrgOpen(!isNewOrgOpen);
  }

  const handleListItemClick = (org) => {
    props.setOrg(org);
  };

  const handleInputChange = event => {
    setOrgName(event.target.value)
  }

  const handleSubmit = ()=> {
    createOrg(newOrgName, props.displayRequestError)
      .then(res => { 
        props.updateOrgList();
        toggleNewOrg(); 
      });
  }

  const handleAcceptOrgInvite = (org, accept) => {
    acceptOrgInvite(org.handle, accept)
      .then(res => {
        if (res && res.success) {
          props.updateOrgList();
          let msg = accept ? 'Accepted' : 'Declined'
          props.setSnackBarMessage(`Invitation ${msg}`, 'success')
        }
      })
  }

  const gotoManufacturerPortal = () => {
    props.openManufacturerPortal()
    history.push('/manufacturer_portal')
  }

  const logoutUser = () => {
    props.logout()
      .then(res => {
        if (props.oidcUser) {
          userManager.signoutRedirect();
        }
        window.sessionStorage.removeItem("Org");
      });
  }

  const isValid = !(newOrgName === '')
  const orgList = superUserSelected ? superOrgs : props.organizations;
  const userAllowed = props.currentUser.is_superuser || props.currentUser.is_manufacturer;
  
  return (
    <Dialog 
      fullWidth
      maxWidth='xs'
      aria-labelledby="select-org-modal" 
      open={!(userAllowed && props.manufacturerPortal )}
    >
      {isNewOrgOpen ?
        <NewOrg
          newOrgName={newOrgName}
          handleInputChange={handleInputChange}
          handleSubmit={handleSubmit}
          toggleNewOrg={toggleNewOrg}
          isValid={isValid}

        /> :
        <ShowOrgs
          toggleSuperUser={toggleSuperUser}
          is_superuser={props.currentUser.is_superuser}
          is_manufacturer={props.currentUser.is_manufacturer}
          gotoManufacturerPortal={gotoManufacturerPortal}
          toggleNewOrg={toggleNewOrg}
          orgList={orgList}
          handleListItemClick={handleListItemClick}
          superUserSelected={superUserSelected}
          logout={logoutUser}
          totalOrgs={totalOrgs}
          page={page}
          handleChangePage={handleChangePage}
          rowsPerPage={rowsPerPage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleAcceptOrgInvite={handleAcceptOrgInvite}
        />
      }
    </Dialog>
  );
}


const SelectOrgMordal = connect(mapStateToProps, mapDispatchToProps)(ConnectedSelectOrgModal)

export default SelectOrgMordal;

// Helper Components
const NewOrg = (props) => {
  const classes = useStyles();
  const {
    newOrgName,
    handleInputChange,
    handleSubmit,
    toggleNewOrg,
    isValid,
  } = props;

  return (
    <div>
      <div className={classes.header}>
        <DialogTitle id="select-org-modal">
          {'Create a new organization'}
        </DialogTitle>
      </div>
      <DialogContent>
        <TextField
          margin="dense"
          label="Name"
          type="text"
          value={newOrgName}
          onChange={handleInputChange}
          placeholder="Give a name to this new org"
          fullWidth
          color='secondary'
          onKeyPress={runOnEnter(handleSubmit)}
        />
      </DialogContent>
      <DialogActions className={classes.dialogssActions}>
        <Button
          color='primary'
          onClick={toggleNewOrg}
        >
          cancel
        </Button>
        <Button
          disabled={!isValid}
          color='secondary'
          variant='contained'
          onClick={handleSubmit}
        >
          {'Create New Org'}
        </Button>
      </DialogActions>
    </div>
  )
}

const ShowOrgs = (props) => {
  const classes = useStyles();
  const {
    toggleSuperUser,
    is_superuser,
    is_manufacturer,
    gotoManufacturerPortal,
    toggleNewOrg,
    orgList,
    handleListItemClick,
    superUserSelected,
    logout,
    totalOrgs,
    page,
    handleChangePage,
    rowsPerPage,
    handleChangeRowsPerPage,
    handleAcceptOrgInvite
  } = props;

  const unConfirmedOrgs = orgList.filter(org => !org.is_confirmed && org.name != 'Unassigned')

  return (
    <div>
      <div className={classes.header}>
        <DialogTitle id="select-org-modal">
          {'Select Organization'}
        </DialogTitle>
        <div style={{ paddingRight: '12px' }}>
          {(is_superuser || is_manufacturer) &&
            <Tooltip title='Manufacturer Portal' placement="top">
              <IconButton
                onClick={gotoManufacturerPortal}
                color='secondary'
                aria-label={`manufacturer portal`}
              >
                <ImportantDevicesIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          }
          {is_superuser && 
            <Tooltip title='Toggle Super User' placement="top">
              <IconButton
                onClick={toggleSuperUser}
                color='secondary'
                aria-label={`superuser`}
              >
                <AllInclusiveIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          }
          <Tooltip title='Add New Org' placement="top">
            <IconButton
              onClick={toggleNewOrg}
              color='secondary'
              edge="end"
              aria-label={`add new org`}
            >
              <AddBoxIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <List>
        {orgList.map((org, index) => {
          if (!org.is_confirmed && org.name != 'Unassigned' && !superUserSelected) {
            return null
          }
          return (
          <ListItem button onClick={() => handleListItemClick(org)} key={index}>
            <ListItemAvatar>
              <Avatar>
                {org.name[0]}
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={org.name} />
          </ListItem>
        )})}
      </List>
      {unConfirmedOrgs.length > 0 && 
        <div className={classes.unconfirmedRoot}>
          <Typography className={classes.text2}>Unconfirmed Organizations</Typography>
          {unConfirmedOrgs.map((org, index) => {
            if (!org.is_confirmed && org.name != 'Unassigned') {
              return (
                <div onClick={() => {}} key={index} style={{display:'flex', alignItems: 'center'}}>
                  <ListItemText primary={org.name} />
                  <div className={classes.btnDiv}>
                    <Button
                      size='small'
                      style={{ marginRight: '16px' }}
                      color='secondary'
                      variant='contained'
                      onClick={() => handleAcceptOrgInvite(org, true)}
                      disableElevation
                    >
                      Accept
                    </Button>
                    <Button
                      size='small'
                      variant='outlined'
                      onClick={() => handleAcceptOrgInvite(org, false)}
                    >
                      Decline
                    </Button>
                  </div>
                </div>
              )
            }
          })}
        </div>
      }
      <DialogActions className={classes.dialogActions}>
        {superUserSelected ?
          <div>
            <TablePagination
              component="div"
              count={totalOrgs}
              labelRowsPerPage=''
              page={page}
              rowsPerPageOptions={[]}
              onChangePage={handleChangePage}
              rowsPerPage={rowsPerPage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </div>
          : <div />
        }
        <Button
          color={'secondary'}
          onClick={logout}
        >
          {'Logout of Knowhere'}
        </Button>
      </DialogActions>
    </div>
  )
}

/*
 Copyright 2019, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

 http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */
import {
  FULL_UPDATE,
  ADD_DEVICE_TO_INSTALL,
  REMOVE_DEVICE_TO_INSTALL,
  REMOVE_MULTIPLE_DEVICES_FROM_INSTALL,
  LOGOUT_USER,
  RENT_UPDATE,
  REMOVE_EVENT_HANDLER,
  ADD_EVENT_HANDLER,
  GET_CATEGORIES
} from "../constants/action-types";

const initialState = {
  itemToInstall: {name: "No Item Selected", handle: null, type: null},
  devicesToInstall: [],
  isAppInstallModalOpen: false,
  isConfirmationModalOpen: {show: false, message: null, onYes: function() {}},
  snackBarMessage: "",
  organizations: [],
  selectedOrg: {name: '', handle: '', is_admin: false},
  isDrawerOpen: false,
  isAuthed: false,
  deviceInfo: {},
  currentUser: {username: '', email: '', is_superuser: false, jwt: ''},
  availableDevices: [],
  myRentals: [],
  eventHandlers: {},
  manufacturerPortal: false,
  categories: []
};

const portalReducer = (state=initialState, action) => {
  switch (action.type) {
    case FULL_UPDATE:
      return Object.assign({}, state, action.payload);

    case ADD_DEVICE_TO_INSTALL:
      let existingDevices = new Set(state.devicesToInstall.map(device => device.handle))
      let filteredList = action.payload.devicesToInstall.filter(device => {
        return !existingDevices.has(device.handle)
      })
      let devicesToInstall = [...state.devicesToInstall, ...filteredList];
      return Object.assign({}, state, { devicesToInstall: devicesToInstall })
 
    case REMOVE_MULTIPLE_DEVICES_FROM_INSTALL:
      let devicesToKeep = state.devicesToInstall.filter((device) => {
        return !action.payload.devicesToRemove.includes(device.handle)
      })
      return { ...state, devicesToInstall: devicesToKeep }

    case REMOVE_DEVICE_TO_INSTALL:
      let devicesNotToBeRemoved = state.devicesToInstall.filter((device) => {
        return device.handle != action.payload.deviceToRemove
      })
      return { ...state, devicesToInstall: devicesNotToBeRemoved }

    case ADD_EVENT_HANDLER:
      return Object.assign(
        {}, 
        state, 
        {eventHandlers: Object.assign({}, state.eventHandlers, {[action.payload.eventHandler]: action.payload.handler})}
      )

    case REMOVE_EVENT_HANDLER:
      let eventHandlers = Object.assign({}, state.eventHandlers);
      delete eventHandlers[action.payload.eventHandler]
      return Object.assign({}, state, {eventHandlers: eventHandlers})

    case LOGOUT_USER:
      return initialState;

    case GET_CATEGORIES:
      return { ...state, categories: action.payload.categories };

    default:
      return state; 
  }
}

export default portalReducer;

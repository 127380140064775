/*
 Copyright 2024, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at
 http://www.apache.org/licenses/LICENSE-2.0
 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */

import React from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { CallbackComponent } from "redux-oidc";
import userManager from "../utils/userManager";
import { setSnackBarMessage } from '../actions/interactions';

function mapDispatchToProps(dispatch) {
  return {
    setSnackBarMessage: (message, type) => dispatch(setSnackBarMessage(message, type)),
  };
}

const useStyles = makeStyles((theme) => ({
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '80vh',
  },
}));

const ConnectedCallback = props => {
  const classes = useStyles();
  let history = useHistory();

  return (
    <CallbackComponent
      userManager={userManager}
      successCallback={() => history.push("/")}
      errorCallback={error => {
        history.push("/");
        console.error(error);
        props.setSnackBarMessage(error, 'error')
      }}
      >
      <div className={classes.spinner}>
        <CircularProgress color="secondary" size={80} thickness={2}/>
      </div>
    </CallbackComponent>
  )
}

const Callback = connect(null, mapDispatchToProps)(ConnectedCallback);

export default Callback;

/*
 Copyright 2019, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

 http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */
import React, { useRef, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import RemoveShoppingCartIcon from '@material-ui/icons/RemoveShoppingCart';
import DevicesIcon from '@material-ui/icons/Devices';
import Typography from '@material-ui/core/Typography';

import { setForRent, fetchMyRentals } from '../utils/Cachengo';
import { setConfirmationModalOpen } from '../actions/interactions';
import { capitalizeWord } from '../utils/Helpers';
import { addEventHandler, removeEventHandler } from '../actions/items';
import CustomTable from './CustomTable';

const mapStateToProps = state => {
  return {
    org: state.portal.selectedOrg,
    deviceInfo: state.portal.devices,
    is_superuser: state.portal.currentUser.is_superuser,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    openConfirmationModal: (item) => dispatch(setConfirmationModalOpen(item)),
    removeEventHandler: (key) => dispatch(removeEventHandler(key)),
    addEventHandler: (key, handler) => dispatch(addEventHandler(key, handler)),
  }
}

const ConnectedMyRentalsTable = props => {
  const tableRef = useRef();

  useEffect(() => {
    props.addEventHandler('MyRentalTable', (message) => {
      if (message.event === 'device_rented' || message.event === 'device_set_rent' || message.event === 'device_set_unrent') {
        refreshData()
      }
    })
    return () => props.removeEventHandler('MyRentalTable')
  }, [tableRef])

  const clearSelectedItems = () => {
    tableRef.current && tableRef.current.clearSelectedItems()
  }

  const handleRemoveRental = rowData =>{
    let handles = rowData.map(device => device.handle)
    return setForRent(handles, false)
      .then(() => {
        refreshData()
        clearSelectedItems()
      });
  }

  const refreshData = () => {
    tableRef.current && tableRef.current.refreshTable()
  }
  
  const deviceIcon = props => {
    return (
      <DevicesIcon {...props} style={{ margin: '-4px 8px 0 0' }} color='secondary' />
    )
  };
  const columnHead = {
    field: 'name', icon: deviceIcon
  }
  const columns = [
    {render: rowData => { 
      return (
        <Grid container>
          <Grid item xs={12}>
            <Typography variant='body2' >
              Price: ${rowData.price}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant='body2' >
              Status: {capitalizeWord(rowData.status)}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant='body2' >
              Cores: {rowData.cores}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant='body2' >
              ARCH: {rowData.arch}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant='body2' >
              RAM: {parseFloat((rowData.ram / Math.pow(1024, 3)).toFixed(1))}GB
            </Typography>
          </Grid>
          {props.is_superuser &&
          <>
            <Grid item xs={12}>
              <Typography variant='body2' >
                VM handle: {rowData.vm_handle}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body2' >
                IP: {rowData.private_ip}
              </Typography>
            </Grid>
          </>
          }
        </Grid>
         )
    }}
  ]

  const unRentShoppingCart = (props) => (
    <RemoveShoppingCartIcon {...props} />
  )
  
  const actions = [
    {
      icon: unRentShoppingCart,
      tooltip: 'Remove from rental',
      onClick: (event, rowData) => {
        props.openConfirmationModal(
          {
            show: true,
            message: "Are you sure you want to remove this device from rental?",
            onYes: () => {handleRemoveRental(rowData)
              .then(props.openConfirmationModal({show: false, message: null, onYes: function() {}}))
            }
          }
        )
      }
    },
  ]

  return (
    <Grid style={{paddingTop: '20px'}} width={1}>
      <CustomTable
        ref={tableRef}
        multiSelect={true}
        title='Rental Devices'
        getData={fetchMyRentals}
        actions={actions}
        columns={columns}
        columnHead={columnHead}
        errMessage={'Problem fetching my rentals'}
      />
    </Grid>
  );
}

const MyRentalsTable = connect(mapStateToProps, mapDispatchToProps)(ConnectedMyRentalsTable);

export default MyRentalsTable;
